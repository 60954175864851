import { PureFunction } from '@bonsai-components/utility-types';
import {
  ADMIN_ACCESS_TOKENS_PATH,
  AUDIT_LOGS_PATH,
  DEPLOYMENT_PATH,
  DEVCENTER_PATH,
  INTEGRATIONS_PATH,
  MARKETPLACE_HOME_PATH,
  NEW_BUILDER_PATH,
  ORGANIZATION_REPOSITORIES_BASE_PATH,
  QUARANTINED_DASHBOARD_PATH,
  RECENT_ACTIVITY_PATH,
  RECIPE_BUILDER_PATH,
  REPORTS_PATH,
  USERS_PATH,
  WORKERS_DASHBOARD_PATH
} from '../../../constants/general';
import {
  AccessTokenIcon,
  AgentsIcon,
  AuditLogsIcon,
  BrowseRecipesIcon,
  BuildRecipesIcon,
  DeployRecipesIcon,
  DevCenterIcon,
  ExplorerIcon,
  HistoryIcon,
  QuarantinedIcon,
  ReportsIcon,
  RepositoryIcon,
  WorkerIcon
} from '../../../icons/icons';
import { ModOrganizationsIcon } from '../../../icons/moderne-icons';

export type LeftNavMenuProps = {
  icon: JSX.Element;
  label: string;
  description: string;
  href?: string | (() => string);
  shortcut?: string;
  badge?: string;
};

export const getLeftNavMenuItems = () => [
  {
    description: 'Holistic view of your organization health and goals',
    href: DEVCENTER_PATH,
    icon: <DevCenterIcon />,
    label: 'DevCenter',
    badge: 'Beta'
  },
  {
    description: 'View available recipes for various technologies',
    href: MARKETPLACE_HOME_PATH,
    icon: <BrowseRecipesIcon />,
    label: 'Marketplace',
    shortcut: 'c'
  },
  {
    description: 'Build your recipe',
    href: RECIPE_BUILDER_PATH,
    icon: <BuildRecipesIcon />,
    label: 'Build',
    shortcut: 'b'
  },
  {
    description: 'See what we are cooking up next',
    href: NEW_BUILDER_PATH,
    icon: <ExplorerIcon />,
    label: 'Builder v2',
    badge: 'Beta'
  },
  {
    description: 'Deploy recipes and visualizations',
    href: DEPLOYMENT_PATH,
    icon: <DeployRecipesIcon />,
    label: 'Deploy',
    shortcut: 'd'
  },
  {
    description: 'View repositories',
    href: ORGANIZATION_REPOSITORIES_BASE_PATH,
    icon: <RepositoryIcon />,
    label: 'Repositories',
    shortcut: 'p' // projects
  },
  {
    description: 'See recent activity across your selected organization',
    href: RECENT_ACTIVITY_PATH,
    icon: <HistoryIcon />,
    label: 'Activity',
    shortcut: 'r'
  }
];

export const getLeftNavAdminMenuItems: PureFunction<
  void,
  Array<LeftNavMenuProps>
> = () => [
  {
    label: 'Agents',
    href: INTEGRATIONS_PATH,
    icon: <AgentsIcon />,
    description: 'View connected agents',
    shortcut: 'a'
  },
  {
    label: 'Audit logs',
    href: AUDIT_LOGS_PATH,
    icon: <AuditLogsIcon />,
    description: 'View audit logs',
    shortcut: 'l'
  },
  {
    label: 'Workers',
    href: WORKERS_DASHBOARD_PATH,
    icon: <WorkerIcon />,
    description: 'View connected workers',
    shortcut: 'w'
  },
  {
    label: 'Quarantine',
    href: QUARANTINED_DASHBOARD_PATH,
    icon: <QuarantinedIcon />,
    description: 'View repositories in quarantined state',
    shortcut: 'q'
  },
  {
    label: 'Reports',
    href: REPORTS_PATH,
    icon: <ReportsIcon />,
    description: 'Download usage reports',
    shortcut: 'r'
  },
  {
    label: 'Access tokens',
    href: ADMIN_ACCESS_TOKENS_PATH,
    icon: <AccessTokenIcon />,
    description: 'Users with access tokens',
    shortcut: 't'
  },
  {
    label: 'Users',
    href: USERS_PATH,
    icon: <ModOrganizationsIcon />,
    description: 'Users of the platform',
    shortcut: 't'
  }
];
