import React, { Component, ReactNode } from 'react';
import {
  RepositoryIdentityFragment,
  RepositoryInput
} from '../__generated__/apollo-hooks';
import { UpdateApplication } from '../components/utilities/update-app/update-app.component';
import {
  DEFAULT_CUSTOMER_SUPPORT_ORG,
  DEFAULT_OPENREWRITE_SUPPORT_ORG,
  GITHUB_PUBLIC_HOST
} from '../constants/general';

export type AuthProviderNames = 'github' | 'bitbucket' | 'sso';

export type CustomerSupportProps = {
  title?: string;
  labels?: string;
  body?: string;
  template?: string;
  destination?: 'support' | 'openrewrite';
};

export type ModerneTenantFeatures = { [key: string]: boolean };
export type ModerneTenantConfiguration = {
  buildId?: number;
  name?: string;
  version?: string;
  brandingWhite?: string;
  brandingColor?: string;
  brandingIcon?: string;
  apiGatewayUrl?: string;
  features?: ModerneTenantFeatures;
  supportRepositoryUrl?: string;
  latestAgentVersion?: string;
};

type ModerneUIState = {
  updateRequired: boolean;
};

type ConfigurationContextProps = {
  children: ReactNode[] | ReactNode;
};

export type ConfigurationContextState = {
  config: ModerneTenantConfiguration;
  internalState: ModerneUIState;
  getCustomerSupportUrl: (request: CustomerSupportProps) => string;
  getWorkerGraphQlUrl: (
    repository: RepositoryInput | RepositoryIdentityFragment
  ) => string;
  getGraphQlUrl: () => string;
  getApiGatewayUrl: () => string;
  getFeature: (featureName: string) => boolean;
  getAuthProviders: () => Array<AuthProviderNames>;
};

const initialState: ConfigurationContextState = {
  config: {},
  internalState: { updateRequired: false },
  getCustomerSupportUrl: () => '',
  getWorkerGraphQlUrl: () => '',
  getGraphQlUrl: () => '',
  getApiGatewayUrl: () => '',
  getFeature: () => false,
  getAuthProviders: () => ['sso']
};

export const ConfigurationContext = React.createContext(initialState);

export class ConfigurationProvider extends Component<
  ConfigurationContextProps,
  ConfigurationContextState
> {
  readonly state: ConfigurationContextState = {
    ...initialState,
    getCustomerSupportUrl: (request) => {
      const { destination, ...rest } = request;
      const searchParams = new URLSearchParams(rest);

      const supportUrl =
        this.state.config.supportRepositoryUrl || destination === 'support'
          ? `${GITHUB_PUBLIC_HOST}/${DEFAULT_CUSTOMER_SUPPORT_ORG}/support-${this.state.config.name}`
          : `${GITHUB_PUBLIC_HOST}/${DEFAULT_OPENREWRITE_SUPPORT_ORG}/rewrite`;

      return `${supportUrl}/issues/new?${searchParams.toString()}`;
    },
    getFeature: (featureName: string): boolean => {
      return this.state.config.features?.[featureName] ?? false;
    },
    getApiGatewayUrl: () => this.state.config.apiGatewayUrl,
    getGraphQlUrl: () => `${this.state.config.apiGatewayUrl}/graphql`,
    getWorkerGraphQlUrl: (repository) => {
      const params = new URLSearchParams({
        path: repository.path,
        branch: repository.branch,
        origin: repository.origin
      });

      return `${
        this.state.config.apiGatewayUrl
      }/worker/graphql?${params.toString()}`;
    },
    getAuthProviders: () => {
      if (this.state.config.name === 'app') {
        return ['github', 'bitbucket'];
      } else {
        return ['sso'];
      }
    }
  };

  private fetchConfig = async (): Promise<ModerneTenantConfiguration> => {
    const resp = await fetch('/api/config');
    const config = await resp.json();
    return config;
  };

  async componentDidMount(): Promise<void> {
    const config = await this.fetchConfig();
    this.setState({
      config
    });
  }
  async componentDidUpdate(): Promise<void> {
    const config = await this.fetchConfig();
    if (this.state.config.buildId < config.buildId) {
      this.setState(() => ({
        config,
        internalState: { updateRequired: true }
      }));
    }
  }

  render(): JSX.Element {
    return (
      <ConfigurationContext.Provider value={this.state}>
        {/**
         * Conditionally render `children` based on the existence of
         * `apiGatewayUrl`
         */}
        {this.state.config.apiGatewayUrl ? this.props.children : null}
        {this.state.internalState.updateRequired && <UpdateApplication />}
      </ConfigurationContext.Provider>
    );
  }
}
