export async function showNotification(
  title: string,
  body: string,
  url?: string
) {
  const canShow = await canShowNotification();
  if (canShow) {
    const options: NotificationOptions = {
      body,
      icon: '/moderne-notification-icon.png',
      badge: '/public/moderne-notification-icon.png'
    };
    const notification = new Notification(title, options);
    if (url && window.location.pathname !== url) {
      notification.onclick = () => {
        window.open(window.location.origin + url, '_blank');
      };
    }
  } else {
    // eslint-disable-next-line no-console
    console.info('Notification permission denied');
  }
}

export async function requestNotificationPermission() {
  if ('Notification' in window) {
    if (
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      await Notification.requestPermission();
    }
  }
}

/**
 * if the user has not previous granted or denied permission, request it.
 * return the results of the previous permission request if it exists.
 * fall back to `false` if Notifications are not supported
 */
export async function canShowNotification() {
  if ('Notification' in window) {
    await requestNotificationPermission();
    return Notification.permission === 'granted';
  } else {
    return false;
  }
}
