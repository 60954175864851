import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { DOCUMENTATION_SITE_URL } from '../../constants/general';
import { Link } from '../utilities/moderne-link/moderne-link.component';

type SearchDocsProps = {
  isAiSearch: boolean;
};

export const SearchDocs: FunctionComponent<SearchDocsProps> = ({
  isAiSearch = false
}) => {
  const searchHash = isAiSearch ? '#ai-search' : '#non-ai-search-tips';

  return (
    <Box>
      <Link
        href={`${DOCUMENTATION_SITE_URL}/user-documentation/how-to-guides/moderne-platform-search${searchHash}`}
        external>
        Search syntax tips
      </Link>
    </Box>
  );
};
