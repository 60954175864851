import { jwtDecode } from 'jwt-decode';
import { NextApiRequest } from 'next';
import { JWT } from 'next-auth/jwt';
import { AuthorizationEndpointHandler } from 'next-auth/providers';
import { getTenantName } from './next.helpers';

export const REFRESH_TOKEN_EXPIRED = 'RefreshTokenExpired';

/**
 * Dynamically build Keycloak Issuer URL based on the current request.
 * Use environment variables if defined, otherwise, attempt to infer from
 * request host.
 *
 * Localhost should always have the environment variable defined
 */
export const keycloakIssuer = (req: NextApiRequest): string => {
  // use env if defined (typically local and Vercel deployments)
  if (process.env.KEYCLOAK_ISSUER) {
    return process.env.KEYCLOAK_ISSUER;
  }
  // otherwise, infer from request host
  const tenant = getTenantName(req);
  if (tenant) {
    return `https://login.${tenant}.moderne.io/auth/realms/${tenant}`;
  } else {
    throw new Error('No KEYCLOAK_ISSUER or tenant found');
  }
};

/**
 * Dynamically get the Keycloak client ID based on the current request.
 * Use environment variables if defined, otherwise, attempt to infer from
 * request host.
 *
 * Localhost should always have the environment variable defined
 */
export const keycloakClientId = (req: NextApiRequest): string => {
  // use env if defined (typically local and Vercel deployments)
  if (process.env.KEYCLOAK_CLIENT_ID) {
    return process.env.KEYCLOAK_CLIENT_ID;
  }
  // otherwise, infer from request host
  const tenant = getTenantName(req);
  if (tenant) {
    return `${tenant}-moderne`;
  } else {
    throw new Error('No KEYCLOAK_CLIENT_ID or tenant found');
  }
};

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
export async function refreshAccessToken(req: NextApiRequest, token: JWT) {
  const url = `${keycloakIssuer(req)}/protocol/openid-connect/token`;

  const postBody = new URLSearchParams({
    client_id: keycloakClientId(req),
    client_secret: process.env.KEYCLOAK_CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: token.refreshToken
  });

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'POST',
    body: postBody
  });

  const refreshedTokens = await response.json();

  if (!response.ok) {
    return {
      ...token,
      error: REFRESH_TOKEN_EXPIRED
    };
  }

  const newToken = {
    ...token,
    accessToken: refreshedTokens.access_token,
    accessTokenExpires: Date.now() + refreshedTokens.expires_in * 1000,
    refreshToken: refreshedTokens.refresh_token ?? token.refreshToken // Fall back to old refresh token
  };
  debugToken(newToken, 'refreshed');
  return newToken;
}

export const debugToken = (jwtToken: Partial<JWT>, origin: string) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  const decodedToken = jwtDecode<{ iat: number; exp: number }>(
    jwtToken.accessToken as string
  );
  const decodedRefreshToken = jwtDecode<{ iat: number; exp: number }>(
    jwtToken.refreshToken as string
  );

  // eslint-disable-next-line no-console
  console.log('[decodedToken]', `[${origin}]`, {
    at: jwtToken.accessToken.slice(-5),
    refAt: jwtToken.refreshToken.slice(-5),
    issued: new Date(decodedToken.iat * 1000),
    expires: new Date(decodedToken.exp * 1000),
    refreshIssued: new Date(decodedRefreshToken.iat * 1000),
    refreshExpires: new Date(decodedRefreshToken.exp * 1000)
  });
};

export const getProviderAuthorizationOptions = (req: NextApiRequest) => {
  let authorization: AuthorizationEndpointHandler = undefined;
  if (req.cookies['moderne-no-hint']) {
    authorization = {
      params: {
        kc_idp_hint: ''
      }
    };
  }
  return authorization;
};

export const determineRedirectUrl = (req: NextApiRequest): string => {
  if (process.env.NEXTAUTH_URL) {
    return process.env.NEXTAUTH_URL;
  }

  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  if (req?.headers?.referer) {
    try {
      const url = new URL(req?.headers?.referer);
      return url.origin;
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};
