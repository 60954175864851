import kebabcase from 'lodash.kebabcase';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';

import { Badge, badgeClasses, Tooltip } from '@mui/material';

import { determineHref } from '../../../helpers/link.helper';
import { Link } from '../../utilities/moderne-link/moderne-link.component';
import {
  LeftNavigationListItemButton,
  LeftNavigationListItemIcon,
  LeftNavigationMenuItemText
} from './left-navigation.styled';
import { LeftNavMenuProps } from './navigation-items';

type LeftNavigationMenuItemProps = {
  menuItem: LeftNavMenuProps;
  onClick?: () => void;
};
export const LeftNavigationMenuItem: FunctionComponent<
  LeftNavigationMenuItemProps
> = ({ menuItem, onClick }) => {
  const { pathname } = useRouter();
  const selectedMenuItemRegex = new RegExp(`^${menuItem.href}`, 'i');
  return (
    <LeftNavigationListItemButton
      sx={{
        paddingLeft: 1.25,
        paddingRight: 2.5
      }}
      data-testid={`global-nav-${kebabcase(menuItem.label)}-button`}
      selected={selectedMenuItemRegex.test(pathname)}
      href={determineHref(menuItem.href)}
      onClick={onClick}
      LinkComponent={Link}>
      <LeftNavigationListItemIcon>{menuItem.icon}</LeftNavigationListItemIcon>
      <Tooltip title={menuItem.description} placement="right" arrow>
        <LeftNavigationMenuItemText>
          {menuItem.label}
        </LeftNavigationMenuItemText>
      </Tooltip>
      {menuItem.badge && (
        <Badge
          badgeContent={menuItem.badge}
          color="primary"
          sx={(theme) => ({
            [`& .${badgeClasses.badge}`]: {
              right: 20,
              backgroundColor: theme.palette.green[575],
              color: theme.palette.common.white,
              fontSize: theme.typography.pxToRem(10.5),
              borderRadius: theme.shape.borderRadius / 4
            }
          })}
        />
      )}
    </LeftNavigationListItemButton>
  );
};
