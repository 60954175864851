import { useEditorContext } from '@graphiql/react';
import { Button, ButtonBase, Popover } from '@mui/material';
import { bindPopover } from 'material-ui-popup-state';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSession } from 'next-auth/react';
import { FunctionComponent, useContext } from 'react';
import { NotificationContext } from '../../contexts/notification.context';
import { GRAPHQL_PINK } from '../../themes/moderne-theme';
import { FlexBox } from '../styled-components/layouts/layouts.styled';

export const ShareGraphQLCurl: FunctionComponent<{ url: string }> = ({
  url
}) => {
  const { queryEditor, variableEditor } = useEditorContext();
  const { renderNotification } = useContext(NotificationContext);
  const { data } = useSession();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'graphql-curl'
  });
  const handleCopy = (query: string, variables: string) => {
    const curlCommand = `curl -X POST ${url} \\
    -H 'Authorization: Bearer ${data.accessToken}' \\
    -H 'Content-Type: application/json' \\
    -d '{ "query": ${JSON.stringify(query)}${variables} }'`;

    navigator.clipboard.writeText(curlCommand.toString()).then(() => {
      renderNotification('success', 'Copied graphql curl command to clipboard');
    });
  };

  return queryEditor?.operations?.length > 1 ? (
    <>
      <ButtonBase {...bindTrigger(popupState)} className={'toolbar-button'}>
        Share curl
      </ButtonBase>
      <Popover {...bindPopover(popupState)}>
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start'
          }}>
          {queryEditor.operations.map(
            ({ loc, name, variableDefinitions, operation }, idx) => {
              let variablesObject = {};
              try {
                variablesObject = JSON.parse(variableEditor.getValue());
              } catch {
                // do nothing;
              }
              const operationVariables = variableDefinitions.reduce(
                (acc, variable) => {
                  const foundVariable =
                    variablesObject[variable.variable.name.value];
                  if (foundVariable) {
                    acc[variable.variable.name.value] = foundVariable;
                  }

                  return acc;
                },
                {}
              );

              const variables =
                Object.keys(operationVariables).length > 0
                  ? `, "variables": ${JSON.stringify(operationVariables)}`
                  : '';

              const query = queryEditor
                .getValue()
                ?.substring(loc.start, loc.end);

              return (
                <Button
                  key={idx}
                  onClick={() => handleCopy(query, variables)}
                  sx={{
                    width: '100%',
                    textTransform: 'none',
                    color: 'inherit',
                    justifyContent: 'flex-start',
                    ':hover': {
                      color: 'white',
                      backgroundColor: GRAPHQL_PINK
                    }
                  }}>
                  {name?.value || `<Unnamed ${operation}>`}
                </Button>
              );
            }
          )}
        </FlexBox>
      </Popover>
    </>
  ) : (
    <ButtonBase
      onClick={() => {
        const query =
          queryEditor.getValue() !== '' ? queryEditor.getValue() : '';

        const variables =
          query !== '' && variableEditor.getValue() !== ''
            ? `, "variables": ${JSON.stringify(variableEditor.getValue())}`
            : '';

        handleCopy(query, variables);
      }}
      className={'toolbar-button'}>
      Share curl
    </ButtonBase>
  );
};
