import { PureFunction } from '@bonsai-components/utility-types';
import { Organization } from '../../@types/repository-groups';

export const MY_ORGANIZATIONS_GROUPING = 'My organizations';
export type OrganizationRowData = {
  id: string;
  name: string;
  description: string;
  repositoryCount: number;
  isUserOrganization: boolean;
  parent?: string;
  path: string[];
};

export const getOrganizationRows: PureFunction<
  Organization[],
  OrganizationRowData[]
> = (organizations) => {
  const rows = organizations
    .map((group) => {
      const paths = getOrganizationHierarchy({
        organizations: organizations,
        organization: group
      });
      return {
        id: group.id,
        name: group.name,
        description: group.description,
        repositoryCount: group.repositories.count,
        isUserOrganization: group.isUserOrganization,
        parent: group?.parent?.name,
        path: paths
      };
    })
    .filter(Boolean);

  // count the number of entries in `rows` where `isOrganization` is false
  const myOrganizationsCount = rows.filter(
    (row) => row.isUserOrganization
  ).length;

  // add in a fake row for the root organization and user organizations
  if (myOrganizationsCount > 0) {
    rows.push({
      id: MY_ORGANIZATIONS_GROUPING,
      name: MY_ORGANIZATIONS_GROUPING,
      description: null,
      repositoryCount: null,
      isUserOrganization: true,
      parent: null,
      path: [MY_ORGANIZATIONS_GROUPING]
    });
  }

  return rows;
};

// get organization hierarchy
// generate a list of strings that represent the hierarchal path of a organization by doing the following:
// 1. if the group has a parent, add the parent's name to the list
// 2. search through the organizations looking for the parent by id
// 3. repeat until the organization has no parent
const getOrganizationHierarchy: PureFunction<
  { organizations: Organization[]; organization: Organization },
  string[]
> = ({ organizations, organization }) => {
  // seed the path with the current group's name as the leaf
  const path = [organization?.name];

  while (organization?.parent) {
    const parentOrganization = organizations.find(
      (group) => group.id === organization.parent.id
    );
    if (parentOrganization) {
      path.unshift(organization.parent.name);
    }
    organization = parentOrganization;
  }

  // add in either 'Organization' or 'User organizations' as the root to fake row groupings
  if (organization?.isUserOrganization) {
    path.unshift(MY_ORGANIZATIONS_GROUPING);
  }
  return path;
};
