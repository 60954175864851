import { Base64EncodedString } from '@bonsai-components/utility-types';
import { NextApiRequest } from 'next';
import { NextIncomingMessage } from 'next/dist/server/request-meta';
import { ModerneTenantFeatures } from '../contexts/config.context';
import { decodeJSONBase64 } from './encoding.helper';

const isBrowser = (): boolean => typeof window !== 'undefined';

/**
 * Shallow render using next/router still causes react to re-render due to
 * the context being updated.  This workaround allows us to change the state
 * of the router without triggering a re-render and still allowing
 * navigation back to the entry.
 *
 * @see https://github.com/vercel/next.js/discussions/18072
 */
export const updateUrlWithoutRerender = (
  newUrl: string,
  historyModifier: 'replace' | 'push' = 'push'
): void => {
  if (typeof window === 'undefined') {
    return;
  }

  if (newUrl === `${window.location.pathname}${window.location.search}`) {
    return;
  }

  window.history[`${historyModifier}State`](
    { ...window.history.state, as: newUrl, url: newUrl },
    '',
    newUrl
  );
};

export const getCurrentBuildVersion = (): string => {
  // Docker
  if (process.env.ARTIFACT_VERSION) {
    return process.env.ARTIFACT_VERSION;
  }
  // Preview
  else if (process.env.VERCEL_GIT_COMMIT_SHA) {
    return process.env.VERCEL_GIT_COMMIT_SHA?.substring(0, 8);
  }
  // local
  else if (process.env.NODE_ENV === 'development') {
    return 'local';
  }
  // unknown
  else {
    return null;
  }
};

const getHostFromWindowOrNextAPIRequest = (
  req?: NextApiRequest | NextIncomingMessage
): string => {
  if (isBrowser()) {
    return window?.location.host;
  } else if (req) {
    return req.headers.host;
  } else {
    // This happens when NextJS does it's build and generates static pages
    return 'unknown-host';
  }
};

/**
 * Get the tenant name from the request url for single-tenant environments
 */
export const getTenantName = (
  req?: NextApiRequest | NextIncomingMessage
): string => {
  const host = getHostFromWindowOrNextAPIRequest(req);

  if (process.env.TENANT_NAME) {
    return process.env.TENANT_NAME;
  } else if (!host.includes('localhost')) {
    const hostParts = host.split('.');
    return hostParts[0];
  } else if (host.includes('localhost')) {
    return 'app';
  } else {
    return null;
  }
};

export const getTenantApiGatewayUrl = () => {
  let apiGateway;
  if (process.env.NODE_ENV === 'production' && process.env.TENANT_NAME) {
    apiGateway = `https://api.${process.env.TENANT_NAME}.moderne.io`;
  } else if (process.env.TENANT_API_GATEWAY_URL) {
    apiGateway = process.env.TENANT_API_GATEWAY_URL;
  } else {
    // Development default URL for `moderne-graphql-api` project
    apiGateway = 'http://localhost:4000';
  }
  return apiGateway;
};

export const getTenantFeatures = () => {
  try {
    return decodeJSONBase64<ModerneTenantFeatures>(
      process.env.TENANT_CAPABILITIES as Base64EncodedString
    );
  } catch {
    return undefined;
  }
};
export const signInWithError = (error: string, callbackUrl: string) => {
  const signInUrl = new URL('/auth/signin', window.location.origin);
  signInUrl.searchParams.append('error', error);
  signInUrl.searchParams.append('callbackUrl', callbackUrl);
  /**
   * replace is used to trigger a navigation event (similar to reload)
   * without this, multiple GraphQL requests that could be in-flight
   * would continue to be processed, evaluate the wrong pathname, and
   * possibly send the user to the sign page where it would resend them there
   * but with a callbackUrl of the sign page itself.
   */
  window.location.replace(signInUrl.toString());
};
