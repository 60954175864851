import { ConfirmProvider } from 'material-ui-confirm';
import { SessionProvider, useSession } from 'next-auth/react';
import { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { ApolloProvider } from '@apollo/client';
import { GlobalKeysProvider } from '@bonsai-components/react-global-keys';
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-license';

import { LeftNavigation } from '../components/navigation/left-nav/left-nav.component';
import { SiteMetaData } from '../components/site-meta-data/site-meta-data.component';
import { FlexBox } from '../components/styled-components/layouts/layouts.styled';
import { AuthorizationControl } from '../components/utilities/authorization/authorization.component';
import { ClientSideOnly } from '../components/utilities/client-side-render/client-only.component';
import { AuthErrorBoundary } from '../components/utilities/error-boundary/auth-error-boundary.component';
import { PageErrorBoundary } from '../components/utilities/error-boundary/page-error-boundary.component';
import { UpdateAgent } from '../components/utilities/update-agent/update-agent.component';
import { WithAdministrative } from '../components/with-administrative/with-administrative.component';
import { defaultConfirmationProps } from '../constants/confirmation';
import { KNOWN_SIGN_IN_ERRORS } from '../constants/messages';
import { CommandPaletteProvider } from '../contexts/command-palette.context';
import {
  ConfigurationContext,
  ConfigurationProvider
} from '../contexts/config.context';
import { DownloadsProvider } from '../contexts/downloads.context';
import { JobNotificationProvider } from '../contexts/job-notification.context';
import {
  NotificationContext,
  NotificationProvider
} from '../contexts/notification.context';
import { UserProvider } from '../contexts/user.context';
import { createApolloClient } from '../helpers/apollo-client.helper';
import { REFRESH_TOKEN_EXPIRED } from '../helpers/keycloak.helper';
import { signInWithError } from '../helpers/next.helpers';
import { useRecentRecipeStore } from '../stores/recent-recipe.store';
import { useUserPreferenceStore } from '../stores/user-preference.store';
import { createModerneTheme } from '../themes/moderne-theme';

LicenseInfo.setLicenseKey(
  '1010e77abbeab7404659012aff517b64Tz05NzA4MyxFPTE3NTY0ODMzNTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=='
);

const inter = Inter({
  subsets: ['latin'],
  display: 'auto'
});

/**
 * Main application component.
 * Used to enforce session management, authentication controls,
 * and other contexts.
 *
 */
function ModerneApp(props: AppProps) {
  const { Component, router } = props;
  const isAuthenticationPage = router.pathname.includes('/auth');
  const isStatusPage = router.pathname.startsWith('/status');
  const theme = createModerneTheme({
    customFontFamily: inter.style.fontFamily
  });
  const { initializeStore } = useRecentRecipeStore();
  useEffect(() => {
    initializeStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticationPage || isStatusPage) {
    return (
      <ConfigurationProvider>
        <ClientSideOnly>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <FlexBox>
              <Container disableGutters maxWidth={false}>
                <Component {...props} />
              </Container>
            </FlexBox>
          </ThemeProvider>
        </ClientSideOnly>
      </ConfigurationProvider>
    );
  } else {
    return (
      <AuthErrorBoundary>
        <ConfigurationProvider>
          <SessionProvider
            // Refetch session every 10 minutes
            refetchInterval={60 * 10}
            // Do not fetch new sessions when tab gains focus
            // Seems to cause /api/auth/session to be called twice for some reason
            // TODO: Revisit this setting relative to the similar document visibility handling we've added
            refetchOnWindowFocus={false}
            // Do not refetch when offline.
            // This should avoid intermittent network issues causing the app to log out
            refetchWhenOffline={false}>
            <ClientSideOnly>
              <SiteMetaData />
              <UserProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <NotificationProvider>
                    <AuthorizedModerneApp {...props} />
                  </NotificationProvider>
                </ThemeProvider>
              </UserProvider>
            </ClientSideOnly>
          </SessionProvider>
        </ConfigurationProvider>
      </AuthErrorBoundary>
    );
  }
}

/**
 * Components in this component are either responsible for handling
 * authentication or authorization based on the session or depend upon a
 * successfully authenticated / authorized user to render.
 */
const AuthorizedModerneApp: FunctionComponent<AppProps> = ({
  Component,
  pageProps
}) => {
  const router = useRouter();
  const { data: session } = useSession();
  const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);
  const { getGraphQlUrl } = useContext(ConfigurationContext);
  const { renderNotification } = useContext(NotificationContext);
  const { disableKeyboardShortcuts } = useUserPreferenceStore();
  const client = useMemo(
    () =>
      createApolloClient({
        accessToken: String(session?.accessToken),
        apiGatewayUrl: getGraphQlUrl(),
        showError: renderNotification
      }),
    [session?.accessToken, getGraphQlUrl, renderNotification]
  );

  const handleVisibilityChange = () => {
    setIsVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false
    );
    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @see https://next-auth.js.org/v3/tutorials/refresh-token-rotation#client-side
  useEffect(() => {
    if (isVisible === undefined) {
      return;
    }

    const sessionInvalid =
      !session?.accessToken || session?.error === REFRESH_TOKEN_EXPIRED;

    if (isVisible && sessionInvalid && router.isReady) {
      // eslint-disable-next-line no-console
      console.warn('Session expired, redirecting to sign in page');
      return signInWithError(
        KNOWN_SIGN_IN_ERRORS.sessionExpired,
        router.asPath
      );
    }
  }, [session, isVisible, router, router.isReady, router.asPath]);

  /**
   * Conditionally render the application based on the session.
   * If there's no session with an access token, we will be unable to issue
   * any GraphQL requests.
   */
  return session?.accessToken ? (
    <ApolloProvider client={client}>
      <PageErrorBoundary key={Component.name}>
        <AuthorizationControl>
          <JobNotificationProvider>
            <DownloadsProvider>
              <GlobalKeysProvider
                useCtrlAsMetaAlternative
                disableKeybindings={disableKeyboardShortcuts}>
                <CommandPaletteProvider>
                  <React.Fragment>
                    <ConfirmProvider defaultOptions={defaultConfirmationProps}>
                      <FlexBox
                        id="moderne-app"
                        sx={{
                          overflow: 'hidden'
                        }}>
                        <LeftNavigation />
                        <PageErrorBoundary key={Component.name}>
                          <Component {...pageProps} />
                        </PageErrorBoundary>
                      </FlexBox>
                    </ConfirmProvider>
                    <WithAdministrative suppressWarning>
                      <UpdateAgent />
                    </WithAdministrative>
                  </React.Fragment>
                </CommandPaletteProvider>
              </GlobalKeysProvider>
            </DownloadsProvider>
          </JobNotificationProvider>
        </AuthorizationControl>
      </PageErrorBoundary>
    </ApolloProvider>
  ) : null;
};

export default ModerneApp;
