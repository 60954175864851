import { PureFunction } from '@bonsai-components/utility-types';
import {
  Option,
  OptionInput,
  RecipeDetailsFragment
} from '../__generated__/apollo-hooks';
import { RecipeForm } from '../pages/recipes/[recipeId]';

export const selectTextOnFocus = (e: React.FocusEvent<HTMLInputElement>) =>
  e.currentTarget.select();

export const isInputAList = (inputType: string): boolean => {
  return ['string[]', 'list', 'set'].includes(inputType.toLowerCase());
};

export enum FormInputType {
  BOOLEAN = 'BOOLEAN',
  NULLABLE_BOOLEAN = 'NULLABLE_BOOLEAN',
  STRING = 'STRING',
  MULTI_LINE_STRING = 'MULTI_LINE_STRING',
  SELECT_IMMUTABLE = 'SELECT_IMMUTABLE',
  SELECT_MUTABLE = 'SELECT_MUTABLE',
  MULTI_SELECT = 'MULTI_SELECT',
  RADIO_GROUP = 'RADIO_GROUP',
  ANNOTATION_PATTERN = 'ANNOTATION_PATTERN',
  METHOD_PATTERN = 'METHOD_PATTERN',
  STRING_ARRAY = 'STRING_ARRAY',
  DATE = 'DATE',
  MULTI_LINE_JSON = 'MULTI_LINE_JSON'
}
export const getInputType = (
  inputType: string,
  helpText: string,
  options?: string[],
  required = true
): FormInputType => {
  if (inputType.toLowerCase() === 'boolean') {
    return required ? FormInputType.BOOLEAN : FormInputType.NULLABLE_BOOLEAN;
  } else if (
    inputType.toLowerCase() === 'string' &&
    (helpText.toLowerCase().includes('snippet') ||
      helpText.toLowerCase().includes('license header') ||
      helpText.toLowerCase().includes('oldkeypath') ||
      helpText.toLowerCase().includes('multiline'))
  ) {
    if (helpText.toLowerCase().includes('json')) {
      return FormInputType.MULTI_LINE_JSON;
    }
    return FormInputType.MULTI_LINE_STRING;
  } else if (
    isInputAList(inputType) &&
    helpText.toLowerCase().includes('multiple options')
  ) {
    return FormInputType.MULTI_SELECT;
  } else if (
    helpText.toLowerCase().includes('method pattern') &&
    !helpText.toLowerCase().includes('annotation pattern')
  ) {
    return FormInputType.METHOD_PATTERN;
  } else if (helpText.toLowerCase().includes('annotation pattern')) {
    return FormInputType.ANNOTATION_PATTERN;
  } else if (
    // removing this because we encountered a case where the `inputType` was
    // `Purpose`. Not sure if we'll need to revert and expand this check
    // so I'm leading a breadcrumb here.
    // inputType.toLowerCase() === 'string' &&
    options?.length > 0
  ) {
    // TODO: find specific pattern
    if (helpText.toLowerCase().includes('or enter a value')) {
      return FormInputType.SELECT_MUTABLE;
    }
    if (options.length > 5) {
      return FormInputType.SELECT_IMMUTABLE;
    }
    return FormInputType.RADIO_GROUP;
  } else if (isInputAList(inputType)) {
    return FormInputType.STRING_ARRAY;
  } else if (helpText.toLowerCase().includes(' date')) {
    return FormInputType.DATE;
  } else {
    return FormInputType.STRING;
  }
};

type RecipeOptionFromForm = Pick<RecipeForm, 'options' | 'inputTypes'> & {
  preserveValues?: boolean;
};

type RecipeOptionFromFormResponse = (key: string) => OptionInput | null;

export const getOptionsFromForm: PureFunction<
  RecipeOptionFromForm,
  RecipeOptionFromFormResponse
> =
  ({ options, inputTypes = {}, preserveValues = false }) =>
  (key) => {
    const value = options[key];
    const inputType = inputTypes?.[key];

    if (
      typeof value === 'string' &&
      inputType === FormInputType.STRING_ARRAY &&
      !Array.isArray(value)
    ) {
      return { name: key, value: value?.split(',') };
    } else if (Array.isArray(value)) {
      return {
        name: key,
        // Remove empty values
        value: value.filter(Boolean)
      };
    } else if (preserveValues || value || String(value) === 'false') {
      return { name: key, value };
    } else {
      return null;
    }
  };

export const updateOptionValues: PureFunction<
  RecipeOptionFromForm & {
    recipe: Pick<RecipeDetailsFragment, 'options'>;
  },
  Array<Option>
> = ({ recipe, options, inputTypes, preserveValues = false }) => {
  // Convert form data into array of options
  const recipeOptions = options
    ? Object.keys(options)
        .map(getOptionsFromForm({ options, inputTypes, preserveValues }))
        .filter(Boolean)
    : [];

  // Update values of cloned recipe with form data
  const updatedOptions = recipeOptions
    .map((option) => {
      const optToUpdate = recipe.options.find(
        (opt) => opt.name === option.name
      );
      if (optToUpdate) {
        return { ...optToUpdate, value: option.value };
      } else {
        return null;
      }
    })
    .filter(Boolean);
  return updatedOptions;
};
