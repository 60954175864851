import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useState
} from 'react';

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus';
import { Box, Stack, dialogActionsClasses } from '@mui/material';
import {
  DataGridProProps,
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';
// eslint-disable-next-line no-restricted-imports
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

import { PureFunction } from '@bonsai-components/utility-types';
import { useFormContext } from 'react-hook-form';
import {
  AllRepositoriesQuery,
  RepositoryFilter,
  RepositoryFragment,
  useAllRepositoriesQuery
} from '../../../__generated__/apollo-hooks';
import {
  mapRepositoryFragmentToId,
  repositoryIdToRepositoryFragment
} from '../../../helpers/repository.helpers';
import { useCursorPagination } from '../../../hooks/use-cursor-pagination.hooks';
import { ServerPaginatedDataGrid } from '../../data-grid/paginated-data-grid/paginated-data-grid.component';
import { GridToolbarWithQuickFilter } from '../../data-grid/toolbars/quick-filter.toolbar.component';
import {
  filterModelToRepositoryFilter,
  sortModelToRepositoryFilter
} from '../../organizations-components/repositories-table/organization-repositories.helpers';
import { FilterButtonSectionHeading } from '../../repository-filter/repository-filter.styled';
import { ModerneDataGrid } from '../../styled-components/data-grids/data-grids.styled';
import { LocaleNumber } from '../../utilities/locale-number/locale-number.component';
import { determineGridColumns } from './user-organization-editor.columns';
import { UserOrganizationFormValues } from './user-organization-editor.component';
import {
  UserOrganizationEditorGridRow,
  determineRepositoryRows
} from './user-organization-editor.rows';

const commonGridConfig: Partial<DataGridProProps> = {
  density: 'compact',
  checkboxSelection: true,
  keepNonExistentRowsSelected: true,
  disableRowSelectionOnClick: false,
  disableMultipleColumnsFiltering: true,
  disableMultipleColumnsSorting: true,
  disableColumnPinning: true,
  disableColumnReorder: true,
  disableColumnSelector: true,
  disableColumnFilter: true,
  slots: {
    toolbar: GridToolbarWithQuickFilter
  }
};

export const UserOrganizationEditorGrid: FunctionComponent<{
  selectedApiRef: MutableRefObject<GridApiPro>;
  selectedRepositories: RepositoryFragment[];
}> = ({ selectedApiRef, selectedRepositories }) => {
  const apiRef = useGridApiRef();
  const { data, refetch, networkStatus, fetchMore } = useAllRepositoriesQuery({
    notifyOnNetworkStatusChange: true
  });

  const [preSelection, setPreSelection] = useState<GridRowSelectionModel>();
  const [postSelection, setPostSelection] = useState<GridRowSelectionModel>();
  const [selectedRows, setSelectedRows] = useState<
    UserOrganizationEditorGridRow[]
  >([]);

  const { setValue, register } = useFormContext<UserOrganizationFormValues>();

  register('hasRepositories', {
    value: selectedRows.length > 0,
    validate: (value) => value
  });

  const { paginationModel, pagesNextCursor, handlePaginationModelChange } =
    useCursorPagination<AllRepositoriesQuery>({
      dataKey: 'repositories',
      refetch
    });

  const loading = isNetworkRequestInFlight(networkStatus);

  /**
   * Fetch all repositories
   * This will loop through all pages available and collect the IDs
   */
  const selectAllRepositories = useCallback(async (): Promise<void> => {
    // while we have a next page, fetch the next page
    let hasNextPage = true;
    let cursor: string | null = null;
    while (hasNextPage) {
      const result = await fetchMore({
        variables: {
          first: paginationModel.pageSize * 10,
          after: cursor,
          filter: {
            ...filterModelToRepositoryFilter(
              apiRef.current.state.filter.filterModel
            )
          }
        }
      });
      const { pageInfo, edges } = result.data?.repositories ?? {};
      hasNextPage = pageInfo?.hasNextPage ?? false;
      cursor = pageInfo?.endCursor ?? null;

      const allRepositories =
        edges?.map(({ node }) => mapRepositoryFragmentToId(node)) ?? [];

      // incrementally update the selectionModel with new repositories
      const selectionModel = apiRef?.current?.getSelectedRows();

      allRepositories
        .filter((id) => !selectionModel.has(id))
        .forEach((id) => selectionModel.set(id, undefined));

      apiRef.current.setRowSelectionModel(Array.from(selectionModel.keys()));

      if (!hasNextPage) {
        break;
      }
    }
  }, [apiRef, fetchMore, paginationModel.pageSize]);

  /**
   * When the user clicks the header checkbox, select all repositories, and
   * update the selection model
   */
  useEffect(() => {
    return apiRef?.current?.subscribeEvent(
      'headerSelectionCheckboxChange',
      ({ value }) => {
        if (value) {
          selectAllRepositories();
        } else {
          apiRef.current.setRowSelectionModel([]);
        }
      }
    );
  }, [apiRef, selectAllRepositories]);

  /**
   * When the selectedRepositories prop changes, update the selection model
   */
  useEffect(() => {
    if (selectedRepositories?.length > 0) {
      const selectionIds = selectedRepositories.map(mapRepositoryFragmentToId);
      apiRef.current.setRowSelectionModel(selectionIds);
    }
  }, [apiRef, selectedRepositories]);

  /**
   * Refetches the repositories with the current filter
   */
  const updateRows: PureFunction<
    {
      filterModel?: GridFilterModel;
      sortModel?: GridSortModel;
    },
    void
  > = ({ filterModel, sortModel }) => {
    let filter: RepositoryFilter = {};
    if (filterModel) {
      filter = { ...filterModelToRepositoryFilter(filterModel) };
    }
    if (sortModel) {
      filter = { ...filter, ...sortModelToRepositoryFilter(sortModel) };
    }
    refetch({
      filter
    }).then(() => {
      apiRef.current.setPage(0);
    });
  };

  /**
   * When the selection model changes, update the rows for the postSelection state
   */
  useEffect(() => {
    setSelectedRows(
      (postSelection || []).map(repositoryIdToRepositoryFragment)
    );
    setValue('hasRepositories', postSelection?.length > 0);
  }, [postSelection, setValue]);

  const availableRows = determineRepositoryRows(data);
  const columns = determineGridColumns();

  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      sx={{
        gap: 1
      }}>
      <Box
        sx={{
          flex: 1
        }}>
        <FilterButtonSectionHeading>
          Available repositories (
          <LocaleNumber
            value={data?.repositories?.count || 0}
            humanReadable={false}
          />
          )
        </FilterButtonSectionHeading>
        <ServerPaginatedDataGrid
          cursor={pagesNextCursor.current[paginationModel.page - 1]}
          pageInfo={data?.repositories?.pageInfo}
          footerOffsetSelector={`.${dialogActionsClasses.root}`}
          apiRef={apiRef}
          loading={loading}
          rows={availableRows}
          columns={columns}
          // Filter
          filterMode={'server'}
          onFilterModelChange={(filterModel) => {
            updateRows({
              filterModel,
              sortModel: apiRef.current?.getSortModel()
            });
          }}
          // Sorting
          sortingMode={'server'}
          onSortModelChange={(sortModel) => {
            updateRows({
              sortModel,
              filterModel: apiRef.current?.state?.filter?.filterModel
            });
          }}
          // Pagination
          paginationMode={'server'}
          onPaginationModelChange={handlePaginationModelChange}
          rowCount={data?.repositories?.count || 0}
          rowSelectionModel={preSelection}
          onRowSelectionModelChange={(model) => {
            setPreSelection(model);
            setPostSelection(model);
          }}
          initialState={{
            pagination: {
              paginationModel
            }
          }}
          paginationModel={paginationModel}
          {...commonGridConfig}
        />
      </Box>
      <Box
        sx={{
          flex: 1
        }}>
        <FilterButtonSectionHeading>
          Selected repositories (
          <LocaleNumber
            value={selectedApiRef?.current?.getSelectedRows?.()?.size ?? 0}
            humanReadable={false}
          />
          )
        </FilterButtonSectionHeading>
        <ModerneDataGrid
          footerOffsetSelector={`.${dialogActionsClasses.root}`}
          apiRef={selectedApiRef}
          rows={selectedRows}
          columns={columns}
          rowSelectionModel={postSelection}
          onRowSelectionModelChange={(model) => {
            setPreSelection(model);
            setPostSelection(model);
          }}
          {...commonGridConfig}
        />
      </Box>
    </Stack>
  );
};
