// Recipe Run Result
export const HIDE_EMPTY_RESULTS = 'Hide no results';

// Global navigation
export const SIGN_OUT = 'Logout of Moderne';
export const SEARCH_BAR_PLACEHOLDER = 'Search recipes';

// Recipe Configuration
export const REQUIRED_FIELD = 'Required';

//  Load External Recipes
export const NO_EXTERNAL_RECIPES_FOUND =
  'Looks like we were unable to find any recipes for that artifact.';

//  Load External Visualizations
export const NO_EXTERNAL_VISUALIZATIONS_FOUND =
  'Looks like we were unable to find any visualizations for that artifact.';

// Recipe Form
export const ERROR_RUNNING_RECIPE =
  'An unexpected error occurred trying to run your recipe. Please try again.';
export const REQUIRED_FIELD_ERROR = 'This field is required.';

// Recipe Builder
export const ADD_TO_BUILDER_CTA = 'Add to builder';
export const ADD_AS_PRECONDITION_CTA = 'Add as precondition';

// Data tables
export const ERROR_NO_ORIGINS =
  'This recipe run did not produce any data for this specific data table.';
export const ERROR_NO_DATA_TABLE_CONTENT =
  'Sorry, this data table does not have any content.';

export const KNOWN_SIGN_IN_ERRORS = {
  sessionExpired: 'Your session has expired. Please sign in again.',
  invalidToken: 'Invalid token'
};
