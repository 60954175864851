import { GlobalKeysContext } from '@bonsai-components/react-global-keys';
import { Backdrop, Box, Paper, Popper } from '@mui/material';
import {
  bindFocus,
  bindPopper,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Recipe } from '../../__generated__/apollo-hooks';
import { SEARCH_INPUT_ID } from '../../constants/general';
import { SEARCH_BAR_PLACEHOLDER } from '../../constants/messages';
import { UserContext } from '../../contexts/user.context';
import {
  focusFirstFocusable,
  focusGlobalSearchInput
} from '../../helpers/focus.helper';
import { handleDownArrow } from '../../helpers/keyboard-event.helper';
import {
  recipeDetailsUrl,
  searchForRecipeUrl
} from '../../helpers/link.helper';
import { useCommonBreakpoints } from '../../hooks/use-breakpoints.hooks';
import { excludeActionFromInputMode } from '../../hooks/use-command-palette.hooks';
import { useStandardDebouncedCallback } from '../../hooks/use-standard-debounce.hooks';
import { SearchIcon } from '../../icons/icons';
import { useUserPreferenceStore } from '../../stores/user-preference.store';
import { searchCommandShortcutSettings } from '../settings/manage-accessibility/keyboard-shortcuts-settings';
import { SearchInputField } from './global-search.styled';
import { PreviousSearchQueries } from './previous-search-queries.component';
import { SearchRecipes } from './search-recipes.component';

export const AutoCompleteSearch: FunctionComponent<{
  toggleMenu?: () => void;
}> = ({ toggleMenu }) => {
  const { previousSearchQueries = [], update } = useUserPreferenceStore();
  const {
    preferences: { searchMenuShortcut }
  } = useContext(UserContext);
  const { addKeyBinding } = useContext(GlobalKeysContext);
  const { isMobileDevice } = useCommonBreakpoints();
  const router = useRouter();

  const searchRef = useRef<HTMLElement>(null);
  const popoverRef = useRef<HTMLElement>(null);

  const popoverState = usePopupState({
    variant: 'popper',
    popupId: 'global-search'
  });

  const methods = useForm<{
    query: string;
  }>();

  const queryValue = methods.watch('query');

  useEffect(() => {
    const shortcutSetting = searchCommandShortcutSettings[searchMenuShortcut];

    shortcutSetting?.bindings.forEach((binding) => {
      addKeyBinding({
        ...binding,
        action: binding.includeInputMode
          ? focusGlobalSearchInput
          : (e) => {
              excludeActionFromInputMode(e, focusGlobalSearchInput);
            }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetState = () => {
    methods.resetField('query');
    popoverState.close();
  };

  const handleInputChange = useStandardDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value.trim() !== '') {
        methods.setValue('query', e.target.value);
        if (!popoverState.isOpen) {
          popoverState.open(searchRef.current);
        }
      } else {
        methods.resetField('query');
      }
    }
  );

  const handleRedirect = (input: string | Recipe): void => {
    if (typeof input === 'string') {
      router.push(searchForRecipeUrl(input.trim()));
    } else if (typeof input !== 'string' && input?.id) {
      router.push(recipeDetailsUrl({ id: input.id }));
    } else {
      return null;
    }
  };

  const updateRecentSearchQueries = (query: string) => {
    if (query) {
      update({
        previousSearchQueries: Array.from(
          new Set([query.trim(), ...previousSearchQueries])
        ).slice(0, 5)
      });
    }
  };

  const handleBlur = () => {
    if (queryValue?.trim() !== '') {
      updateRecentSearchQueries(queryValue);
    }
  };

  const handleFormSubmit = (data) => {
    if (!handleInputChange.isPending() || data.override) {
      updateRecentSearchQueries(data.query);
      handleRedirect(data.query || '');
      resetState();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if ((e.key === 'Tab' && e.shiftKey) || e.key === 'Escape') {
      e.preventDefault();
      return resetState();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      return handleFormSubmit({
        query: (e.target as HTMLInputElement).value,
        override: true
      });
    }
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      return focusFirstFocusable(popoverRef.current);
    }
    handleDownArrow(e);
  };

  const showRecipeResults = Boolean(
    queryValue?.trim() && queryValue.trim() !== ''
  );
  const showPreviousTerms =
    Boolean(!queryValue || queryValue.trim() === '') &&
    previousSearchQueries.length > 0;

  const handleBackdropClick = (_e: React.MouseEvent) => {
    resetState();
  };

  return (
    <FormProvider {...methods}>
      <Box ref={searchRef}>
        <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
          <SearchInputField
            {...methods.register('query', {})}
            {...bindFocus(popoverState)}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            id={SEARCH_INPUT_ID}
            fullWidth
            autoComplete="off"
            placeholder={SEARCH_BAR_PLACEHOLDER}
            inputProps={{
              'data-testid': 'global-search-input'
            }}
            endAdornment={<SearchIcon color="action" />}
          />
        </form>
        <Popper
          {...bindPopper(popoverState)}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: { xs: 0, sm: isMobileDevice ? 8 : 56 }
                }
              }
            ],
            placement: isMobileDevice ? 'bottom-start' : 'right-start'
          }}
          sx={{ zIndex: (theme) => theme.zIndex.modal }}
          open={
            popoverState.isOpen && (showPreviousTerms || showRecipeResults)
          }>
          <Paper
            sx={{
              boxShadow: (theme) => theme.shadows[10],
              maxWidth: { xs: '90vw', md: '70vw' }
            }}
            tabIndex={-1}>
            <Box ref={popoverRef}>
              {showPreviousTerms && <PreviousSearchQueries />}
              {showRecipeResults && (
                <SearchRecipes
                  searchTerm={queryValue}
                  resetModal={resetState}
                  toggleMenu={toggleMenu}
                />
              )}
            </Box>
          </Paper>
        </Popper>
        <Backdrop
          // we aren't using the grey translucent backdrop with Redesign23(tm)
          invisible
          // negative z-index to prevent focus from being trapped in the backdrop when trying to interact with the search input
          sx={{ zIndex: '-1' }}
          open={popoverState.isOpen}
          onClick={handleBackdropClick}
        />
      </Box>
    </FormProvider>
  );
};
