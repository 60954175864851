import React, { FunctionComponent, useContext, useRef } from 'react';

import { Button, Stack } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext
} from '@mui/x-data-grid-pro';

import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { OrganizationInput } from '../../@types/repository-groups';
import { DOHERTY_THRESHOLD } from '../../constants/general';
import { NotificationContext } from '../../contexts/notification.context';
import { repositoryFragmentToInput } from '../../helpers/repository-filter.helper';
import {
  useOrganizations,
  useUpsertUserOrganization
} from '../../hooks/use-organizations.hooks';
import { AddToListIcon, ImportFromFileIcon } from '../../icons/icons';
import { ToolbarActions } from '../data-grid/toolbar-actions/toolbar-actions.component';
import { FlexBox } from '../styled-components/layouts/layouts.styled';
import { OrganizationsApiExample } from './organizations-api-example.component';
import { UserOrganizationDialog } from './user-organization-editor/user-organization-dialog.component';

export const MyOrganizationsToolbar: FunctionComponent = () => {
  const apiRef = useGridApiContext();
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'addOrganization'
  });

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'Enter') {
      event.preventDefault();
      const firstRowOrg = gridPaginatedVisibleSortedGridRowIdsSelector(
        apiRef.current.state,
        apiRef.current.instanceId
      );
      if (firstRowOrg?.length > 0) {
        apiRef.current.setCellFocus(
          firstRowOrg.at(0),
          apiRef.current.getPinnedColumns().left.at(1)
        );
      }
    }
  };

  return (
    <GridToolbarContainer>
      <Stack
        sx={{
          width: '100%'
        }}>
        <FlexBox
          sx={{
            justifyContent: 'space-between'
          }}>
          <GridToolbarQuickFilter
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            autoComplete="off"
            debounceMs={DOHERTY_THRESHOLD}
            onKeyDown={handleKeyDown}
          />
          <ToolbarActions>
            <OrganizationsApiExample />
            <Button
              size="small"
              variant="contained"
              {...bindTrigger(popupState)}
              startIcon={<AddToListIcon fontSize="inherit" />}>
              Create user organization
            </Button>
            <ImportUserOrganizationButton />
          </ToolbarActions>
          {popupState.isOpen && (
            <UserOrganizationDialog
              open={popupState.isOpen}
              onClose={popupState.close}
            />
          )}
        </FlexBox>
      </Stack>
    </GridToolbarContainer>
  );
};

const ImportUserOrganizationButton: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { renderNotification } = useContext(NotificationContext);
  const upsertUserOrganization = useUpsertUserOrganization();
  const { loading, organizations } = useOrganizations();

  const importOrganization = async (
    incomingOrganization: OrganizationInput
  ): Promise<boolean> => {
    const existingOrganization = organizations.some((org) => {
      if (incomingOrganization.id) {
        return org.id === incomingOrganization.id;
      }
      return org.name === incomingOrganization.name;
    });

    if (!existingOrganization) {
      await upsertUserOrganization({
        name: incomingOrganization.name,
        description: incomingOrganization.description,
        repositoryInputs: incomingOrganization.repositories?.map(
          repositoryFragmentToInput
        )
      });
      return true;
    } else {
      return false;
    }
  };

  const handleFileSelect = async () => {
    if (!fileInputRef.current?.files?.length) {
      return;
    }
    const file = fileInputRef.current.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const contents = event.target?.result as string;
        const json = JSON.parse(contents);

        // if the json is an array, loop through it and import each one
        // create a Map to track the names of the imported groups that were successful and those that were not
        const importedGroups = new Map<string, boolean>();
        if (Array.isArray(json)) {
          for (const organization of json) {
            const result = await importOrganization(organization);
            importedGroups.set(organization.name, result);
          }
        } else {
          importedGroups.set(json.name, await importOrganization(json));
        }

        // create a string of the names of the groups that were not imported
        const failedGroups = Array.from(importedGroups.entries())
          .filter((entry) => !entry[1])
          .map((entry) => entry[0])
          .join(', ');
        // get a count of the groups that were imported
        const importedCount = Array.from(importedGroups.values()).filter(
          (value) => value
        ).length;

        // if there were any failed groups, display a notification
        if (failedGroups) {
          renderNotification(
            'error',
            `The following user-configured organization were not imported: ${failedGroups}`
          );
        } else {
          renderNotification(
            'success',
            `Successfully imported ${importedCount} user-configured organization(s)`
          );
        }
      } catch (error) {
        renderNotification(
          'error',
          `Error importing user-configured organization(s)`,
          error.message
        );
      } finally {
        // Reset the form after successful load
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    };

    reader.readAsText(file);
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        hidden
        onChange={handleFileSelect}
      />
      <Button
        disabled={loading}
        startIcon={<ImportFromFileIcon />}
        size="small"
        variant="contained"
        color="primary"
        onClick={() => fileInputRef.current?.click()}>
        Import
      </Button>
    </>
  );
};
