import { Avatar, Box } from '@mui/material';
import { FunctionComponent, useContext } from 'react';

import { ConfigurationContext } from '../../../contexts/config.context';
import { findWords } from '../../../helpers/string.helper';

/**
 * To add a supported logo:
 *  - prepend name to target to this array
 *  - add svg of logo to /public/logos with the same name
 *    - file name should be in lowercase
 *    - svg should ideally be 400px by 400px
 */
const supportedLogos = [
  // New Logos go here 👇
  // lodash words translates `ff4j` as `[ff,4,j]`
  { logo: 'ff4j', aliases: ['ff'] },
  { logo: 'unleash' },
  { logo: 'openfeature' },
  { logo: 'docker' },
  { logo: 'nodejs', aliases: ['node'] },
  { logo: 'okhttp', aliases: ['okio'] },
  { logo: 'plexus' },
  { logo: 'codehaus' },
  { logo: 'vue' },
  { logo: 'svelte' },
  { logo: 'storybook' },
  { logo: 'refaster', aliases: ['Error Prone Support', 'refasterrules'] },
  { logo: 'picnic', aliases: ['tech'] },
  { logo: 'jest' },
  { logo: 'angular', aliases: ['ng'] },
  { logo: 'lodash' },
  { logo: 'react' },
  { logo: 'mui' },
  { logo: 'nextjs' },
  { logo: 'openapi' },
  { logo: 'micrometer' },
  { logo: 'launchdarkly' },
  { logo: 'Hibernate' },
  { logo: 'Jenkins' },
  { logo: 'axonframework' },
  { logo: 'Python' },
  { logo: 'sql' },
  { logo: 'kotlin' },
  { logo: 'cobol' },
  { logo: 'groovy' },
  { logo: 'cucumber' },
  { logo: 'mockito' },
  { logo: 'Testcontainers' },
  { logo: 'Properties' },
  { logo: 'Concourse' },
  { logo: 'Azure' },
  { logo: 'AWS', aliases: ['amazon', 'awssdk'] },
  { logo: 'GCP' },
  { logo: 'UBC' },
  { logo: 'CircleCI' },
  { logo: 'GitHub', aliases: ['Dependabot'] },
  { logo: 'Gradle' },
  { logo: 'Micronaut' },
  { logo: 'Terraform' },
  { logo: 'JHipster' },
  { logo: 'Spring' },
  { logo: 'Kubernetes' },
  { logo: 'Quarkus' },
  { logo: 'Controlflow', aliases: ['dataflow'] },
  { logo: 'Cloudsuitability', aliases: ['suitability'] },
  // Generic fallback images go here 👇
  { logo: 'featureflags' },
  { logo: 'Moderne' },
  { logo: 'csharp' },
  { logo: 'javascript', aliases: ['codemods'] },
  { logo: 'JSON' },
  { logo: 'Java', aliases: ['junit'] },
  { logo: 'Maven', aliases: ['apache'] },
  { logo: 'XML' },
  { logo: 'YAML' },
  { logo: 'Hcl' },
  { logo: 'Core' },
  // We don't want "ai" to match for things like io.moderne.ai so we drop the priority
  { logo: 'timefold', aliases: ['ai'] },
  { logo: 'Recommendations' },
  { logo: 'Openrewrite' },
  { logo: 'customrecipe', aliases: ['software'] }
].map((supportedLogo) => ({
  logo: supportedLogo.logo.toLowerCase(),
  aliases: supportedLogo.aliases?.map((alias) => alias.toLowerCase()) ?? []
}));

type CategoryImageSelectorProps = {
  name: string;
  height?: number | string;
  shadow?: boolean;
  renderFallback?: boolean;
  preserveSpace?: boolean;
  transparentBackground?: boolean;
  fallback?: string;
};

const XSMALL_DEVICE_RATIO = 0.5;
const SMALL_DEVICE_RATIO = 0.67;

/**
 * Uses the name to determine if an svg logo should be displayed or a default
 * visual
 */
export const CategoryImageSelector: FunctionComponent<
  CategoryImageSelectorProps
> = ({
  name,
  height = 48,
  shadow = true,
  renderFallback = true,
  preserveSpace = false,
  transparentBackground = false,
  fallback
}) => {
  const { config } = useContext(ConfigurationContext);

  const isHeightNumber = typeof height === 'number';
  const sxHeight = isHeightNumber
    ? {
        xs: height * XSMALL_DEVICE_RATIO,
        sm: height * SMALL_DEVICE_RATIO,
        md: height
      }
    : height;

  if (!name) {
    return null;
  }
  const lowerWords = findWords(name.toLowerCase()).map((word) =>
    word.toLowerCase()
  );

  const supportedLogo = supportedLogos.find(
    ({ logo, aliases }) =>
      lowerWords.includes(logo) ||
      aliases.some((alias) => lowerWords.includes(alias))
  );

  if (supportedLogo) {
    return (
      <Avatar
        src={`/logos/${supportedLogo.logo}.svg`}
        alt={name}
        sx={{
          boxShadow: shadow ? 2 : 0,
          backgroundColor: transparentBackground
            ? 'transparent'
            : (theme) => theme.palette.common.white,
          height: sxHeight,
          width: sxHeight
        }}
      />
    );
  }

  /**
   * At this point none of the supported logos apply so we will look to see if a
   * tenant branding logo is defined and if the name contains the tenant name. If
   * so we will use the tenant branding logo.
   */
  if (config.brandingColor || config.brandingIcon) {
    const lowerTenant = config?.name?.toLowerCase();

    const willUseTenantBranding = name
      .toLowerCase()
      .includes(lowerTenant || lowerTenant.replace(/[-_]/g, ''));

    if (willUseTenantBranding) {
      return (
        <Avatar
          style={{ height, width: height }}
          alt={name}
          sx={{
            boxShadow: shadow ? 2 : 0,
            backgroundColor: (theme) => theme.palette.common.white,
            height: sxHeight,
            width: sxHeight
          }}>
          <img
            src={decodeURIComponent(
              config.brandingIcon || config.brandingColor
            )}
            alt={'tenant logo'}
            style={{
              padding: config.brandingIcon ? 10 : 4, // TODO: revisit this padding; only tested with all state
              maxHeight: height,
              maxWidth: height
            }}
          />
        </Avatar>
      );
    }
  }

  /**
   * If the logo is not supported then we will just return a circle with the
   * first letter of the name
   */
  if (renderFallback) {
    if (fallback) {
      return (
        <Avatar
          src={`/logos/${fallback}.svg`}
          alt={name}
          sx={{
            boxShadow: shadow ? 2 : 0,
            backgroundColor: transparentBackground
              ? 'transparent'
              : (theme) => theme.palette.common.white,
            height: sxHeight,
            width: sxHeight
          }}
        />
      );
    }
    return (
      <Avatar
        alt={name}
        sx={{
          boxShadow: shadow ? 2 : 0,
          height: sxHeight,
          width: sxHeight
        }}>
        {name.split('.').pop()[0].toUpperCase()}
      </Avatar>
    );
  }
  if (preserveSpace) {
    return (
      <Box
        sx={{
          height: height,
          width: height
        }}
      />
    );
  }
  return null;
};
