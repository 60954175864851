import { useSession } from 'next-auth/react';
import { forwardRef, FunctionComponent, useContext } from 'react';

import {
  Box,
  ListItemButton,
  listItemButtonClasses,
  ListItemIcon,
  Typography
} from '@mui/material';

import { BRANDING_HEIGHT, BRANDING_WIDTH } from '../../constants/general';
import { ConfigurationContext } from '../../contexts/config.context';
import { getAvatarSource } from '../../helpers/avatar.helper';
import { ModerneUserAvatar } from '../styled-components/avatars/avatars.styled';

export const UserAvatar: FunctionComponent = forwardRef((props, ref) => {
  const { data: session } = useSession();
  const avatarSource = getAvatarSource(session);
  const avatarInitials = (
    session?.user?.fullName || session?.user?.userName
  )?.charAt(0);

  const { config } = useContext(ConfigurationContext);

  return (
    <ListItemButton
      {...props}
      sx={{
        my: 1,
        [`&.${listItemButtonClasses.selected}, &.${listItemButtonClasses.root}:hover`]:
          {
            backgroundColor: 'blue.100'
          }
      }}>
      <ListItemIcon>
        <ModerneUserAvatar
          {...ref}
          alt={session.user.userName}
          src={avatarSource}
          sx={{
            width: 32,
            height: 32
          }}>
          {avatarInitials}
        </ModerneUserAvatar>
      </ListItemIcon>
      {config.brandingColor ? (
        <Box
          sx={{
            display: 'inline-flex',
            height: BRANDING_HEIGHT,
            maxHeight: BRANDING_HEIGHT,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <img
            src={config.brandingColor}
            alt={config.name}
            style={{
              alignSelf: 'center',
              maxWidth: BRANDING_WIDTH,
              height:
                config.brandingColor.split('.').at(-1) === 'svg'
                  ? BRANDING_HEIGHT
                  : undefined,
              maxHeight: BRANDING_HEIGHT
            }}
          />
        </Box>
      ) : (
        <Typography
          sx={{
            color: 'secondary'
          }}>
          {session?.user?.userName}
        </Typography>
      )}
    </ListItemButton>
  );
});
