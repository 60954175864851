import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import {
  Base64EncodedString,
  FunctionComponentWithChildren
} from '@bonsai-components/utility-types';

import {
  useExchangeAzureDevOpsAuthorizationCodeMutation,
  useExchangeBitbucketAuthorizationVerifierMutation,
  useExchangeBitbucketCloudAuthorizationCodeMutation,
  useExchangeGithubAuthorizationCodeMutation,
  useExchangeGitLabAuthorizationCodeMutation
} from '../../../__generated__/apollo-hooks';
import { INVALID_TOKEN_ERROR_PATH } from '../../../constants/general';
import { OAuthState } from '../../../helpers/authorization.helper';
import { decodeJSONBase64 } from '../../../helpers/encoding.helper';
import { cleanUrlParams } from '../../../helpers/link.helper';
import { Layout } from '../../layout/layout.component';
import {
  FlexBox,
  FullHeightBox
} from '../../styled-components/layouts/layouts.styled';
import { ModerneGraphQLError } from '../graphql-error/graphql-error.component';
import { Loading } from '../loading/loading.component';

export const AuthorizationControl: FunctionComponentWithChildren = ({
  children
}) => {
  const [error, setError] = useState<ApolloError>(null);
  const router = useRouter();
  const { query, events } = router;
  const [exchangeGitLabCode] = useExchangeGitLabAuthorizationCodeMutation();
  const [exchangeGitHubCode] = useExchangeGithubAuthorizationCodeMutation();
  const [exchangeBitbucketCloudCode] =
    useExchangeBitbucketCloudAuthorizationCodeMutation();
  const [exchangeBitBucketCode] =
    useExchangeBitbucketAuthorizationVerifierMutation();
  const [exchangeAzureDevOpsCode] =
    useExchangeAzureDevOpsAuthorizationCodeMutation();

  const handleError = useCallback(
    (error: ApolloError) => {
      setError(error);
      const query = cleanUrlParams();
      router.replace({
        pathname: window.location.pathname,
        query
      });
    },
    [setError, router]
  );

  // Reset error state when navigating away from this page
  useEffect(
    () => events.on('beforeHistoryChange', () => setError(null)),
    [events]
  );

  useEffect(() => {
    if (query.code && query.state) {
      const oauthState = decodeJSONBase64<OAuthState>(
        query.state as Base64EncodedString
      );

      const { redirectUri, origin, provider } = oauthState;

      if (provider === 'azure-devops') {
        exchangeAzureDevOpsCode({
          variables: {
            origin,
            code: String(query.code),
            redirectUri: window.location.origin,
            clientId: oauthState.clientId,
            tenantId: oauthState.tenantId
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      } else if (provider === 'gitlab') {
        exchangeGitLabCode({
          variables: {
            code: String(query.code),
            // GitLab does not accept wildcard redirect URIs beyond the TLD
            redirectUri: window.location.origin,
            codeVerifier: oauthState.codeVerifier,
            origin
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      } else if (provider === 'github') {
        exchangeGitHubCode({
          variables: {
            code: String(query.code),
            redirectUri: window.location.origin,
            origin: String(query.origin)
          }
        })
          .then(({ data }) => {
            if (data?.exchangeScmCode) {
              const params = cleanUrlParams();
              router.replace(
                window.location.origin +
                  window.location.pathname +
                  (params ? `?${params}` : '')
              );
            } else {
              router.replace(INVALID_TOKEN_ERROR_PATH);
            }
          })
          .catch(handleError);
      } else if (provider === 'bitbucket-cloud') {
        exchangeBitbucketCloudCode({
          variables: {
            code: String(query.code),
            redirectUri: window.location.origin,
            origin: origin
          }
        })
          .then(() => router.replace(redirectUri))
          .catch(handleError);
      }
    } else if (query.oauth_token && query.oauth_verifier && query.origin) {
      exchangeBitBucketCode({
        variables: {
          origin: String(query.origin),
          requestToken: String(query.oauth_token),
          verifier: String(query.oauth_verifier)
        }
      })
        .then(({ data }) => {
          if (data?.exchangeScmCode) {
            const params = cleanUrlParams();
            router.replace(
              window.location.origin +
                window.location.pathname +
                (params ? `?${params}` : '')
            );
          } else {
            router.replace(INVALID_TOKEN_ERROR_PATH);
          }
        })
        .catch(handleError);
    }
  }, [
    exchangeAzureDevOpsCode,
    exchangeBitBucketCode,
    exchangeBitbucketCloudCode,
    exchangeGitHubCode,
    exchangeGitLabCode,
    handleError,
    query,
    router
  ]);

  if (error) {
    return (
      <React.Fragment>
        <FlexBox>
          <Layout title="Authorization failed" isLoading={false}>
            <ModerneGraphQLError error={error} />
          </Layout>
        </FlexBox>
      </React.Fragment>
    );
  } else if (query.code || (query.oauth_token && query.oauth_verifier)) {
    return (
      <FullHeightBox
        sx={{
          my: 4
        }}>
        <Loading caption="Authorizing" />
      </FullHeightBox>
    );
  } else {
    return <>{children}</>;
  }
};
