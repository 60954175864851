import { PureFunction } from '@bonsai-components/utility-types';
import { useCallback, useEffect, useState } from 'react';
import { GraphQLOperations } from '../__generated__/apollo-helpers';
import {
  CreateUserOrganizationMutationResult,
  CreateUserOrganizationMutationVariables,
  OrganizationsByIdQuery,
  RepositoryFragment,
  RepositoryInput,
  UpdateUserOrganizationMutationResult,
  UpdateUserOrganizationMutationVariables,
  useCreateUserOrganizationMutation,
  useOrganizationsByIdLazyQuery,
  useOrganizationsQuery,
  useUpdateUserOrganizationMutation
} from '../__generated__/apollo-hooks';

import { Organization } from '../@types/repository-groups';
import { mapRepositoryFragmentToId } from '../helpers/repository.helpers';
import { useSelectedOrganizationStore } from '../stores/organization-store';

const getNodeWithId = ({ node }) => ({
  ...node,
  id: mapRepositoryFragmentToId(node)
});

export const useGetAllOrganizationRepositories = (
  id?: string
): {
  loading: boolean;
  data: OrganizationsByIdQuery;
  getOrganizationRepositories: (params: {
    id: string;
  }) => Promise<RepositoryFragment[]>;
} => {
  const [loading, setLoading] = useState(false);
  const [fetchOrganizationRepository, { fetchMore, data }] =
    useOrganizationsByIdLazyQuery();

  const getOrganizationRepositories = async ({ id }: { id: string }) => {
    setLoading(true);
    const response = await fetchOrganizationRepository({
      variables: {
        id
      }
    });

    let hasNextPage =
      response?.data?.organization?.repositories?.pageInfo?.hasNextPage;
    let afterCursor =
      response?.data?.organization?.repositories?.pageInfo?.endCursor;

    let nodes =
      response?.data?.organization?.repositories?.edges?.map(getNodeWithId);

    while (hasNextPage) {
      const nextPageNodes = await fetchMore({
        variables: {
          after: afterCursor
        }
      });

      hasNextPage =
        nextPageNodes?.data?.organization?.repositories?.pageInfo?.hasNextPage;
      afterCursor =
        nextPageNodes?.data?.organization?.repositories?.pageInfo?.endCursor;
      const newNodes =
        nextPageNodes?.data?.organization?.repositories?.edges?.map(
          getNodeWithId
        );
      nodes = [
        ...nodes,
        ...newNodes.filter((node) => !nodes?.some((d) => d.id === node.id))
      ];
    }

    setLoading(false);
    return nodes;
  };

  const memoizedGetOrganizationRepositories = useCallback(
    getOrganizationRepositories,
    [fetchMore, fetchOrganizationRepository]
  );

  useEffect(() => {
    if (id) {
      memoizedGetOrganizationRepositories({ id });
    }
  }, [memoizedGetOrganizationRepositories, id]);

  return { loading, data, getOrganizationRepositories };
};

type UpsertUserOrganizationParams = {
  id?: string;
  name: string;
  description: string;
  repositoryInputs: RepositoryInput[];
};

type UpsertUserOrganizationResult =
  | CreateUserOrganizationMutationResult['data']['createUserOrganization']
  | UpdateUserOrganizationMutationResult['data']['updateUserOrganization'];

export const useUpsertUserOrganization: PureFunction<
  void,
  (
    params: UpsertUserOrganizationParams
  ) => Promise<UpsertUserOrganizationResult>
> = () => {
  const [createUserOrganization] = useCreateUserOrganizationMutation();
  const [updateUserOrganization] = useUpdateUserOrganizationMutation();

  return async ({
    id,
    name,
    description,
    repositoryInputs
  }: UpsertUserOrganizationParams) => {
    if (id) {
      const userOrganizationInput: UpdateUserOrganizationMutationVariables['userOrganization'] =
        {
          id,
          name: name,
          repositories: repositoryInputs,
          description: description
        };

      return (
        await updateUserOrganization({
          variables: { userOrganization: userOrganizationInput },
          refetchQueries: [GraphQLOperations.Query.organizations]
        })
      ).data?.updateUserOrganization;
    } else {
      const userOrganizationInput: CreateUserOrganizationMutationVariables['userOrganization'] =
        {
          name: name,
          repositories: repositoryInputs,
          description: description
        };
      return (
        await createUserOrganization({
          variables: {
            userOrganization: userOrganizationInput
          },
          refetchQueries: [GraphQLOperations.Query.organizations]
        })
      ).data?.createUserOrganization;
    }
  };
};

export const useOrganizations = () => {
  const { data, loading } = useOrganizationsQuery({
    fetchPolicy: 'cache-first'
  });
  const { selectedOrganizationId, update } = useSelectedOrganizationStore();

  const setSelectedOrganization = (id: string) => {
    update({ selectedOrganizationId: id });
  };

  useEffect(() => {
    if (!selectedOrganizationId) {
      update({
        selectedOrganizationId: determineSelectedOrganization(
          selectedOrganizationId,
          data?.organizations
        )?.id
      });
    }
  }, [data, selectedOrganizationId, update]);

  return {
    organizations: data?.organizations,
    loading,
    selectedOrganization: determineSelectedOrganization(
      selectedOrganizationId,
      data?.organizations
    ),
    setSelectedOrganization,
    getOrganizationById: (id: string) =>
      (data?.organizations || []).find(({ id: orgId }) => id === orgId)
  };
};

const determineSelectedOrganization = (
  selectedOrganization: string,
  organizations: Organization[]
) => {
  return (
    (organizations || []).find(({ id }) => id === selectedOrganization) ||
    organizations?.[0]
  );
};
