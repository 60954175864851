import { Box, Divider, Fade, List, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';

import { useAutoCompleteResultsQuery } from '../../__generated__/apollo-hooks';
import { DOHERTY_THRESHOLD } from '../../constants/general';
import {
  handleDownArrow,
  handleUpArrow
} from '../../helpers/keyboard-event.helper';
import {
  handleMiddleClickOpen,
  recipeDetailsUrl
} from '../../helpers/link.helper';
import { multipleTermsToCrossFieldQuery } from '../../helpers/search.helpers';
import { useUiFeaturesStore } from '../../stores/ui-features.store';
import {
  CenteredBox,
  OpposingBoxes
} from '../styled-components/layouts/layouts.styled';
import { KeyStroke } from '../styled-components/typography/typography.styled';
import { Loading } from '../utilities/loading/loading.component';
import { SearchDocs } from './ai-search-docs.component';
import { CompactRecipeSearchResult } from './compact-recipe-search-result.component';
import { RecipeListItemButton, SectionHeading } from './global-search.styled';

type SearchRecipesProps = {
  searchTerm: string;
  resetModal: React.Dispatch<React.SetStateAction<null>>;
  toggleMenu?: () => void;
};

export const SearchRecipes: FunctionComponent<SearchRecipesProps> = ({
  searchTerm,
  resetModal,
  toggleMenu
}) => {
  const { useAiSearch } = useUiFeaturesStore();

  const router = useRouter();

  const { resetField } = useFormContext();

  const resetState = () => {
    resetField('query');
    resetModal(null);
    toggleMenu && toggleMenu();
  };

  const { data, loading } = useAutoCompleteResultsQuery({
    variables: {
      query: useAiSearch
        ? searchTerm
        : multipleTermsToCrossFieldQuery(searchTerm),
      featureAi: useAiSearch
    },
    fetchPolicy: 'cache-first'
  });

  const handleRecipeNavigation = (id) => (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      resetState();
      router.push(recipeDetailsUrl({ id }));
    }
    handleDownArrow(e, e.target.parentElement);
    handleUpArrow(e);
  };

  return (
    <Box
      tabIndex={-1}
      sx={{
        width: 'auto',
        minWidth: { xs: 300, sm: 400 }
      }}>
      <SectionHeading tabIndex={-1}>
        <OpposingBoxes>
          <Box>Recipes:</Box>
          <Box>
            {data?.searchRecipes?.count && (
              <Typography
                variant="caption"
                sx={{
                  color: 'textSecondary'
                }}>
                {data.searchRecipes.count} results
              </Typography>
            )}
          </Box>
        </OpposingBoxes>
      </SectionHeading>
      <Divider tabIndex={-1} />
      <Box
        tabIndex={-1}
        sx={{
          overflow: 'scroll',
          height: 300
        }}>
        {!data && loading && (
          <CenteredBox
            sx={{
              marginTop: 2
            }}>
            <Loading caption="Searching for recipes" wait={DOHERTY_THRESHOLD} />
          </CenteredBox>
        )}
        {data?.searchRecipes?.edges.length === 0 && (
          <Box
            sx={{
              m: 1
            }}>
            <Typography>No results found</Typography>
          </Box>
        )}

        {data?.searchRecipes.edges.length > 0 && (
          <List>
            {data?.searchRecipes.edges?.map(({ node: { id, name } }) => (
              <RecipeListItemButton
                key={id}
                onClick={handleRecipeNavigation(id)}
                onMouseDown={handleMiddleClickOpen(recipeDetailsUrl({ id }))}
                onKeyDown={handleRecipeNavigation(id)}
                tabIndex={0}>
                <CompactRecipeSearchResult id={id} name={name} />
              </RecipeListItemButton>
            ))}
          </List>
        )}
      </Box>
      <Divider tabIndex={-1} />
      <Box
        sx={{
          paddingY: 0.5,
          paddingX: 1,
          bgcolor: (theme) => theme.palette.blue[100],
          minHeight: data?.searchRecipes?.count ? '3.5rem' : '1.8rem'
        }}>
        <Fade in={Boolean(!loading)}>
          <Typography
            variant="caption"
            sx={{
              fontSize: 12,
              color: (theme) => theme.palette.grey[700]
            }}>
            {data?.searchRecipes?.count > 0 && (
              <React.Fragment>
                Press
                <KeyStroke>Enter</KeyStroke>
                to see more or navigate with up/down arrow keys
              </React.Fragment>
            )}
            <SearchDocs isAiSearch={useAiSearch} />
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};
