/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Alert,
  AlertProps,
  AlertTitle,
  Box,
  BoxProps,
  Snackbar,
  SnackbarOrigin
} from '@mui/material';
import React, { Component, ReactElement, ReactNode } from 'react';

type NotificationContextProps = {
  children: ReactNode[] | ReactNode;
};

type Severity = AlertProps['severity'];

type NotificationOptions = {
  title?: string;
  placement?: SnackbarOrigin;
  maxWidth?: BoxProps['maxWidth'];
};

export type NotificationContextState = {
  showNotification: boolean;
  notification: ReactElement | string;
  renderNotification: (
    severity: Severity,
    notification: ReactElement | string,
    options?: NotificationOptions
  ) => void;
  resetState: () => void;
  severity?: Severity;
  options?: NotificationOptions;
};

const initialState: NotificationContextState = {
  showNotification: false,
  notification: null,
  renderNotification: () => {},
  resetState: () => {}
};

export const NotificationContext = React.createContext(initialState);

/**
 * Provides a context for rendering notifications in the form of alerts in a
 * snackbar.
 */
export class NotificationProvider extends Component<
  NotificationContextProps,
  NotificationContextState
> {
  readonly state: NotificationContextState = {
    ...initialState,
    renderNotification: (severity, notification, options = {}) => {
      this.setState(() => ({
        showNotification: true,
        notification,
        severity,
        options
      }));
    },
    resetState: () => {
      this.setState(() => ({
        notification: initialState.notification,
        showNotification: initialState.showNotification,
        severity: undefined
      }));
    }
  };

  render(): JSX.Element {
    return (
      <>
        <NotificationContext.Provider value={this.state}>
          {this.props.children}
        </NotificationContext.Provider>
        <Snackbar
          autoHideDuration={6000}
          anchorOrigin={
            this.state.options?.placement ?? {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }
          open={this.state.showNotification}
          onClose={this.state.resetState}>
          {this.state.notification && (
            <Alert
              elevation={6}
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              severity={this.state.severity}
              onClose={this.state.resetState}>
              {this.state.options?.title && (
                <AlertTitle>{this.state.options?.title}</AlertTitle>
              )}
              <Box maxWidth={this.state.options?.maxWidth || '100%'}>
                {this.state.notification}
              </Box>
            </Alert>
          )}
        </Snackbar>
      </>
    );
  }
}
