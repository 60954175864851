import { AppBar, styled } from '@mui/material';
import { TOP_BANNER_HEIGHT } from '../../../constants/general';

export const ModerneAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  height: TOP_BANNER_HEIGHT,
  zIndex: theme.zIndex.fab - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));
