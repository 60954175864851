import { Box, InputBase, ListItemButton, styled } from '@mui/material';

export const SectionHeading = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  padding: theme.spacing(1)
}));

export const RecipeListItemButton = styled(ListItemButton)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingTop: '.4rem',
  paddingBottom: '.4rem',
  ['&:focus, &:hover']: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    outline: 'none'
  }
}));

export const SearchInputField = styled(InputBase)(({ theme }) => ({
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[600]}`,
  background: `linear-gradient(45deg, ${theme.palette.indigo[50]}, ${theme.palette.blue[100]})`,
  backdropFilter: 'blur(3px)',
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.grey[600],
    opacity: 1 // some browsers reduce the opacity
  },
  '&:focus-within': {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.blue[300]}`,
    boxShadow: `0 0 0 0.2rem ${theme.palette.blue[200]}66`
  }
}));
