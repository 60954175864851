import { Filter } from '@mui/icons-material';
import { Alert, Box, DialogActions, DialogContent } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import { useRouter } from 'next/router';
import { RepositoryFragment } from '../../../__generated__/apollo-hooks';
import { ORGANIZATIONS_PATH } from '../../../constants/general';
import { repositoryFragmentToInput } from '../../../helpers/repository-filter.helper';
import {
  RepositoryForUserOrganization,
  mapRepositoryFragmentToId,
  repositoryIdToRepositoryFragment
} from '../../../helpers/repository.helpers';
import {
  useGetAllOrganizationRepositories,
  useOrganizations,
  useUpsertUserOrganization
} from '../../../hooks/use-organizations.hooks';
import { SpacedDivider } from '../../styled-components/layouts/layouts.styled';
import { CancelDialogAction } from '../../utilities/dialog/cancel-dialog-action.component';
import { ModerneGraphQLError } from '../../utilities/graphql-error/graphql-error.component';
import { UserOrganizationApiExample } from './user-organization-editor-api-example.component';
import { UserOrganizationEditorGrid } from './user-organization-editor.table.component';
import { UserOrganizationForm } from './user-organization-form.component';

export type UserOrganizationFormValues = {
  name: string;
  id: string;
  description: string;
  hasRepositories: boolean;
  cloneOrganizationId?: string;
};

type RepoGroupIdentityFragment = RepositoryFragment & {
  status?: string;
};

type RepositoryGroupEditorProps = {
  toggleDialog?: () => void;
  immutableRepositories?: RepoGroupIdentityFragment[];
  id?: string;
  copy?: boolean;
};

export const UserOrganizationEditor: React.FunctionComponent<
  RepositoryGroupEditorProps
> = ({ toggleDialog, immutableRepositories, copy = false, id }) => {
  const { getOrganizationById, setSelectedOrganization } = useOrganizations();

  let defaultValues;

  if (id && !copy) {
    const organization = getOrganizationById(id);
    defaultValues = {
      id: organization?.id,
      name: organization?.name,
      description: organization?.description,
      hasRepositories: organization?.repositories?.count > 0
    };
  } else {
    defaultValues = {
      id: '',
      name: '',
      description: '',
      hasRepositories: false
    };
  }

  const methods = useForm<UserOrganizationFormValues>({
    shouldUnregister: true,
    mode: 'onChange',
    defaultValues
  });

  const orgId = methods.watch('cloneOrganizationId', id);

  const selectedApiRef = useGridApiRef();
  const upsertUserOrganization = useUpsertUserOrganization();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const { data, loading } = useGetAllOrganizationRepositories(orgId);
  const router = useRouter();

  const existingOrganization = getOrganizationById(id || '');

  const [selectedRepositoryList, setSelectedRepositoryList] = useState<
    RepositoryForUserOrganization[]
  >([]);

  useEffect(() => {
    if (!loading && data?.organization?.repositories?.edges?.length > 0) {
      const nodes = data?.organization?.repositories?.edges.map(({ node }) => ({
        ...node,
        id: mapRepositoryFragmentToId(node)
      }));
      setSelectedRepositoryList(nodes);
    } else if (immutableRepositories) {
      setSelectedRepositoryList(immutableRepositories);
    }
  }, [immutableRepositories, loading, data]);

  const handleSave: SubmitHandler<UserOrganizationFormValues> = async (
    data
  ) => {
    setIsSaving(true);
    const repositories = immutableRepositories
      ? immutableRepositories
      : Array.from(selectedApiRef.current.getSelectedRows().keys()).map(
          repositoryIdToRepositoryFragment
        );

    const repositoryInputs = repositories?.map(repositoryFragmentToInput);
    const doUpdate = !copy && existingOrganization?.isUserOrganization;

    try {
      const userOrganizationResult = await upsertUserOrganization({
        id: doUpdate ? existingOrganization?.id : undefined,
        name: data.name,
        description: data.description,
        repositoryInputs
      });

      setSelectedOrganization(userOrganizationResult.id);

      setIsSaving(false);
      if (toggleDialog) {
        toggleDialog();
      } else {
        router.push(ORGANIZATIONS_PATH);
      }
    } catch (error) {
      setIsSaving(false);
      setError(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSave)}>
        <DialogContent dividers={Boolean(toggleDialog)}>
          <UserOrganizationForm
            currentName={copy ? undefined : existingOrganization?.name}
            showCopyFrom={!copy}
          />
          {!immutableRepositories && (
            <Box>
              <SpacedDivider />
              {methods.formState.errors.hasRepositories && (
                <Box
                  sx={{
                    maxWidth: 'sm'
                  }}>
                  <Alert severity="warning">
                    Please select at least one repository before saving
                  </Alert>
                </Box>
              )}
              {error && <ModerneGraphQLError error={error} />}
              <UserOrganizationEditorGrid
                selectedApiRef={selectedApiRef}
                selectedRepositories={selectedRepositoryList}
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <UserOrganizationApiExample
            apiRef={selectedApiRef}
            immutableRepositories={immutableRepositories}
            id={existingOrganization?.id}
          />
          <CancelDialogAction onClose={toggleDialog} />
          <LoadingButton
            loading={isSaving}
            data-testid="save-named-group-button"
            type="submit"
            variant="contained"
            size="small"
            startIcon={<Filter />}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
};
