import { PureFunction } from '@bonsai-components/utility-types';

import {
  RepositoryFragment,
  RepositoryIdentityFragment,
  RepositoryInput,
  isRepositoryABitbucketCloudRepository,
  isRepositoryABitbucketRepository,
  isRepositoryAGitHubRepository,
  isRepositoryAGitLabRepository
} from '../__generated__/apollo-hooks';
import { WorkerRepositoryFragment } from '../__generated__/apollo-hooks-worker';
import {
  DEVCENTER_PATH,
  MARKETPLACE_RECIPES_PATH,
  NEW_BUILDER_PATH,
  ORGANIZATION_REPOSITORIES_BASE_PATH,
  SUPPORT_EMAIL
} from '../constants/general';
import {
  DefaultParam,
  encodeJSONBase64,
  encodeRecipeDefaults,
  toBase64
} from './encoding.helper';
import { getOrganizationAndNameFromPath } from './repository.helpers';

type RecipeDetailsUrlProps = {
  id: string;
  defaultOptions?: DefaultParam;
  selectedRepository?: RepositoryInput | RepositoryFragment;
  organizationId?: string;
};
/**
 *
 * @param {string} id  Recipe ID Example: `org.openrewrite.recipe.someJavaFoo`
 * @returns {string} Example: `/recipes/org.openrewrite.recipe.someJavaFoo`
 */
export const recipeDetailsUrl: PureFunction<RecipeDetailsUrlProps, string> = ({
  id,
  defaultOptions,
  selectedRepository,
  organizationId
}): string => {
  const recipeParams = new URLSearchParams();

  if (organizationId) {
    recipeParams.set('organizationId', toBase64(organizationId));
  }

  if (selectedRepository) {
    recipeParams.set('repository', encodeJSONBase64(selectedRepository));
  }

  let defaults = '';

  if (Array.isArray(defaultOptions) && defaultOptions.length > 0) {
    defaults = `#defaults=${encodeRecipeDefaults(defaultOptions)}`;
  }

  const hasEntries = [...recipeParams.entries()].length > 0;

  return `/recipes/${id}${hasEntries ? `?${recipeParams.toString()}` : ''}${defaults}`;
};

type ReplayBuilderUrlProps = {
  recipeRunId: string;
  organizationId?: string;
};

export const replayBuilderUrl: PureFunction<ReplayBuilderUrlProps, string> = ({
  recipeRunId,
  organizationId
}): string => {
  const recipeParams = new URLSearchParams();

  if (organizationId) {
    recipeParams.set('organizationId', toBase64(organizationId));
  }

  const hasEntries = [...recipeParams.entries()].length > 0;

  return `${NEW_BUILDER_PATH}/${recipeRunId}${
    hasEntries ? `?${recipeParams.toString()}` : ''
  }`;
};

/**
 *
 * @param {string} recipeRunId  Recipe ID Example: `ympPF`
 * @returns {string} Example: `/results/ympPF`
 */
export const recipeResultsUrl = (
  recipeRunId: string,
  params?: string
): string => `/results/${recipeRunId}${params ? `?${params}` : ''}`;

export const dataTablesUrl = (resultId: string): string =>
  `/results/${resultId}/data-tables`;

export const sonarIssueUrl = (id: string): string =>
  `https://sonarsource.github.io/rspec/#/rspec/S${id.replace(/[a-z]+-s?/i, '')}`;
/**
 *
 * @param {string} query Example: `org.openrewrite`
 * @returns {string} Example: `/catalog/org.openrewrite
 */
export const recipeCategoryUrl: PureFunction<{ id: string }, string> = ({
  id
}): string => `${MARKETPLACE_RECIPES_PATH}/${encodeURIComponent(id)}`;

type ScmLinkProps = {
  repository: WorkerRepositoryFragment | RepositoryIdentityFragment;
  branch?: string;
  path?: string;
  sha?: string;
  line?: number;
};

/**
 * Generates a link to a repositories landing page on whatever SCM system it
 * originated from. Currently supports GitHub and BitBucket.
 */
export const scmLinkBuilder: PureFunction<ScmLinkProps, string> = ({
  repository,
  path,
  sha,
  line
}) => {
  // create a new URL object to conditionally append paths and query string parameters
  const url = new URL(getBaseScmLink(repository));
  const shaOrBranch = sha || repository.branch;

  // FIXME: Add link generation for AzureDevOps
  if (isRepositoryAGitHubRepository(repository)) {
    url.pathname = [url.pathname, path ? 'blob' : 'tree', shaOrBranch, path]
      .filter(Boolean)
      .join('/');
    if (line) {
      url.hash = `#L${line}`;
    }
  } else if (isRepositoryABitbucketRepository(repository)) {
    url.pathname = [url.pathname, 'browse', path].filter(Boolean).join('/');
    if (sha) {
      url.searchParams.append('at', sha);
    } else {
      url.searchParams.append('at', encodeURI(`refs/heads/${shaOrBranch}`));
    }
    if (line) {
      url.hash = line.toString();
    }
  } else if (isRepositoryABitbucketCloudRepository(repository)) {
    // url should be pathname and then:
    // - branch: /branch/{branch}/{path}
    // - commit: /src/{sha}/{path}
    url.pathname = [url.pathname, sha ? 'src' : 'branch', shaOrBranch, path]
      .filter(Boolean)
      .join('/');
    if (line) {
      url.hash = `lines-${line}`;
    }
  } else if (isRepositoryAGitLabRepository(repository)) {
    url.pathname = [
      url.pathname,
      '-',
      path ? 'blob' : 'tree',
      shaOrBranch,
      path
    ]
      .filter(Boolean)
      .join('/');
    if (line) {
      url.hash = `L${line}`;
    }
  }

  return url.toString();
};

const getBaseScmLink = (
  repository: WorkerRepositoryFragment | RepositoryIdentityFragment
): string => {
  if (isRepositoryABitbucketRepository(repository)) {
    // 😒
    const { organization, name } = getOrganizationAndNameFromPath(
      repository.path
    );
    return `https://${repository.origin}/projects/${organization}/repos/${name}`;
  } else {
    return `https://${repository.origin}/${repository.path}`;
  }
};

type RepoResultUrl = {
  id: string;
  repository: RepositoryIdentityFragment;
  referrer?: string;
  onlyShowErrors?: boolean;
};

export const repoResultUrl: PureFunction<RepoResultUrl, string> = ({
  id,
  repository,
  referrer,
  onlyShowErrors
}) => {
  const qs = new URLSearchParams();
  if (onlyShowErrors) {
    qs.append('onlyShowErrors', 'true');
  }
  if (referrer) {
    qs.set('referrer', referrer);
  }

  // only include the fields that are required to construct a minimally valid
  // RepositoryInput object
  const { __typename, origin, path, branch } = repository;

  return `/results/${id}/details/${encodeJSONBase64({
    __typename,
    origin,
    path,
    branch
  })}?${qs.toString()}`;
};

export const cleanUrlParams = (keys = []): string => {
  const removeKeys = [
    'code',
    'oauth_token',
    'oauth_verifier',
    'server_url',
    'origin',
    'state',
    'code_verifier',
    'code_challenge',
    ...keys
  ];
  const urlParams = new URLSearchParams(window.location.search);
  removeKeys.forEach((key) => urlParams.delete(key));

  return urlParams.toString() || '';
};

export const repositoryDetailsPath: PureFunction<
  | {
      origin: RepositoryFragment['origin'];
      path: RepositoryFragment['path'];
      branch: RepositoryFragment['branch'];
    }
  | RepositoryInput,
  string
> = ({ path, branch, origin }) => {
  return `${ORGANIZATION_REPOSITORIES_BASE_PATH}/${path}${
    branch ? `?branch=${branch}&origin=${origin}` : ''
  }`;
};

export const markdownCodeBlock = (code: string): string =>
  `\`\`\`\n${code}\n\`\`\``;

export const commitResultsUrl = (id: string): string => `/commits/${id}`;

export const handleMiddleClickOpen = (url: string) => (event) =>
  event.button === 1 &&
  window.open(url, '_blank', 'noopener=true,noreferrer=true');

export const visualizationsForRecipeRunUrl: PureFunction<
  { recipeRunId: string; fromVisualizationRun?: string },
  string
> = ({ recipeRunId, fromVisualizationRun }): string => {
  const path = `${recipeResultsUrl(recipeRunId)}/visualizations`;

  if (fromVisualizationRun) {
    return `${path}?fromVisualizationRun=${fromVisualizationRun}`;
  }

  return path;
};

export const visualizationRunUrl: PureFunction<{ id: string }, string> = ({
  id
}): string => `/visualizations/${id}`;

/**
 *
 * @param {string} query Example: `spring`
 * @returns {string} Example: `/search?q=spring`
 */
export const searchForRecipeUrl = (query: string): string =>
  `/search?q=${encodeURIComponent(query)}`;

/**
 * Returns the URL for the dev center based on the organization ID.
 * If the organization ID is not provided or is 'null', it returns the default dev center path.
 *
 * @param {string} params.organizationId - The organization ID.
 * @returns {string} - The URL for the dev center.
 */
export const devCenterUrl: PureFunction<{ organizationId: string }, string> = ({
  organizationId
}) =>
  organizationId && organizationId !== 'null'
    ? `${DEVCENTER_PATH}/${encodeURIComponent(organizationId)}`
    : DEVCENTER_PATH;

/**
 * Determines the href value based on the provided input.
 * If the input is a function, it will be executed and its return value will be used as the href.
 * If the input is a string, it will be directly used as the href.
 *
 * @param href - The href value or a function that returns the href value.
 * @returns The determined href value.
 */
export const determineHref = (href: string | (() => string)): string => {
  if (typeof href === 'function') {
    return href();
  }
  return href;
};

/**
 * Opens the specified URL in a new browser tab.
 *
 * @param url - The URL to open.
 */
export const openInNewTab = (url: string) =>
  window.open(url, '_blank', 'noopener=true,noreferrer=true');

export const isRightOrMiddleClick = (e: React.MouseEvent) =>
  e.button >= 1 || (e.button === 0 && (e.ctrlKey || e.metaKey));

export const createSupportEmailUrl = ({ subject, body }) => {
  return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${encodeURI(body)}`;
};
