import React, { FunctionComponent, useEffect, useState } from 'react';

import { usePopupState } from 'material-ui-popup-state/hooks';
import { CurrentOrganizationCard } from '../current-organization-card/current-organization-card.component';
import { UserOrganizationDialog } from '../user-organization-editor/user-organization-dialog.component';

type OrganizationSelectorProps = {
  open?: boolean;
};

export const OrganizationSelector: FunctionComponent<
  OrganizationSelectorProps
> = (props) => {
  const { open } = props;
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'organization-context-selector'
  });

  useEffect(() => {
    if (open) {
      popupState.open();
    }
  }, [open, popupState]);

  const [showDialog, setShowDialog] = useState(false);

  const toggleDialog = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setShowDialog(!showDialog);
  };

  return (
    <React.Fragment>
      <CurrentOrganizationCard popupState={popupState} />
      <UserOrganizationDialog open={showDialog} onClose={toggleDialog} />
    </React.Fragment>
  );
};
