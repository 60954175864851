import 'graphiql/graphiql.css';

import { DocumentNode, parse, print as pretty } from 'graphql';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useState } from 'react';

import { createGraphiQLFetcher } from '@graphiql/toolkit';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  Skeleton
} from '@mui/material';

import {
  FunctionComponentWithChildren,
  UnknownRecord
} from '@bonsai-components/utility-types';
import {
  bindDialog,
  bindTrigger,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { ConfigurationContext } from '../../contexts/config.context';
import { CollapseDialogIcon, ExpandDialogIcon } from '../../icons/icons';
import { GraphiQLContainer } from '../styled-components/tools/graphiql.styled';
import { ClosableDialogTitle } from '../utilities/dialog/closable-dialog-title.component';
import { ExpandCollapse } from '../utilities/expand-collapse/expand-collapse.component';
import { ShareGraphQLCurl } from './share-graphql-curl.component';
import { ShareGraphiQL } from './share-graphql-query.component';

const DynamicGraphiQL = dynamic(() => import('graphiql'), { ssr: false });

export type GraphQLExplorerOperationVariables<T = UnknownRecord> =
  | (() => T)
  | T
  | Promise<T>;

type GraphQLExplorerDialog = {
  operationDocument: DocumentNode | string;
  operationVariables?: GraphQLExplorerOperationVariables;
  endpointUrl?: string;
};

type GraphQLExplorerProps = {
  buttonProps?: Partial<ButtonProps>;
  dialogProps?: Partial<DialogProps>;
  graphQLProps: GraphQLExplorerDialog;
};

export const GraphQLExplorer: FunctionComponentWithChildren<
  GraphQLExplorerProps
> = ({ buttonProps = {}, children, dialogProps = {}, graphQLProps }) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'graphql-explorer'
  });

  return (
    <React.Fragment>
      <Button
        title="Click to view how to run this recipe directly with GraphQL"
        sx={{ textTransform: 'none', textAlign: 'left' }}
        {...buttonProps}
        {...bindTrigger(popupState)}>
        {children || 'See how to run against the API'}
      </Button>
      <GraphQLExplorerDialog
        popupState={popupState}
        slotProps={{
          dialog: dialogProps,
          graphqlExplorer: graphQLProps
        }}
      />
    </React.Fragment>
  );
};
type GraphQLExplorerDialogProps = {
  popupState: ReturnType<typeof usePopupState>;
  slotProps: {
    dialog?: Partial<DialogProps>;
    graphqlExplorer: Partial<GraphQLExplorerDialog>;
  };
};

export const GraphQLExplorerDialog: FunctionComponentWithChildren<
  GraphQLExplorerDialogProps
> = ({ slotProps, popupState }) => {
  const { operationDocument, operationVariables, endpointUrl } =
    slotProps.graphqlExplorer;

  const [variables, setVariables] = useState<UnknownRecord | null>(null);

  // a really dumb way to pretty print the variables
  const prettyPrintJson = (variables: UnknownRecord): string => {
    if (!variables) {
      return '';
    }
    return JSON.stringify(JSON.parse(JSON.stringify(variables)), null, 2);
  };

  useEffect(() => {
    if (operationVariables instanceof Promise) {
      operationVariables.then(setVariables);
    } else if (operationVariables instanceof Function) {
      setVariables(operationVariables());
    } else {
      setVariables(operationVariables);
    }
  }, [operationVariables]);

  const { getGraphQlUrl } = useContext(ConfigurationContext);
  const graphqlURL = endpointUrl || getGraphQlUrl();

  const fetcher = createGraphiQLFetcher({
    url: graphqlURL
  });

  const [accessToken, setAccessToken] = useState('');

  const [isFullScreen, setIsFullScreen] = useState(
    slotProps?.dialog?.fullScreen || false
  );

  const { data: session } = useSession();

  const initialQuery = pretty(
    parse(
      typeof operationDocument === 'string'
        ? operationDocument
        : operationDocument.loc.source.body.trim()
    )
  );

  useEffect(() => {
    setAccessToken(session.accessToken as string);
  }, [session.accessToken]);

  return (
    <React.Fragment>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth={!isFullScreen ? 'lg' : undefined}
        {...(slotProps?.dialog || {})}
        {...bindDialog(popupState)}
        fullScreen={isFullScreen}>
        <ClosableDialogTitle
          title={`Moderne API @ ${graphqlURL}`}
          onClose={popupState.close}
          secondaryButton={
            <ExpandCollapse
              color="default"
              isExpanded={isFullScreen}
              onClick={() => setIsFullScreen(!isFullScreen)}
              expandIcon={<ExpandDialogIcon />}
              collapseIcon={<CollapseDialogIcon />}
            />
          }
        />
        <DialogContent sx={{ p: 0 }}>
          <GraphiQLContainer
            sx={{
              minHeight: isFullScreen ? '100%' : '60vh',
              height: isFullScreen ? '100%' : '60vh',
              maxWidth: isFullScreen ? '100%' : '90vw'
            }}>
            {operationVariables && !variables ? (
              <Skeleton width={400} height={400} />
            ) : (
              <DynamicGraphiQL
                defaultVariableEditorOpen
                headerEditorEnabled
                fetcher={fetcher}
                query={initialQuery}
                variables={prettyPrintJson(variables)}
                headers={prettyPrintJson({
                  Authorization: `Bearer ${accessToken}`
                })}
                toolbar={{
                  additionalContent: (
                    <>
                      <ShareGraphiQL url={graphqlURL} />
                      <ShareGraphQLCurl url={graphqlURL} />
                    </>
                  )
                }}
              />
            )}
          </GraphiQLContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
