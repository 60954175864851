import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Fab,
  Fade,
  Toolbar,
  Typography
} from '@mui/material';
import Head from 'next/head';
import { ReactNode, useState } from 'react';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { useInView } from 'react-intersection-observer';
import {
  DOHERTY_THRESHOLD,
  DRAWER_WIDTH_EXPANDED,
  TOP_BANNER_HEIGHT
} from '../../constants/general';
import { useCommonBreakpoints } from '../../hooks/use-breakpoints.hooks';
import { UpArrowIcon } from '../../icons/icons';
import {
  Breadcrumb,
  BreadcrumbsComponent
} from '../navigation/breadcrumbs/breadcrumb.component';
import {
  CenteredBox,
  CenteredOpposingBoxes
} from '../styled-components/layouts/layouts.styled';
import { Loading } from '../utilities/loading/loading.component';
import { WithAdministrative } from '../with-administrative/with-administrative.component';
import { StickyBox } from './paper.component';

type LayoutProps = {
  title: string;
  subheading?: string;
  disableContainerGutters?: boolean;
  breadcrumbs?: Breadcrumb[];
  pageTitle?: ReactNode | string;
  sticky?: boolean;
  isLoading: boolean;
  requiresAdministrativeRole?: boolean;
  maxWidth?: ContainerProps['maxWidth'];
  action?: JSX.Element;
  slots?: {
    banner?: ReactNode;
  };
} & BoxProps;

/**
 *
 * @param title {string} Applied to HTML document `<title>`
 * @param pageTitle {string|ReactNode} displayed after breadcrumbs within `<Typography>`
 */
export const Layout: FunctionComponentWithChildren<LayoutProps> = ({
  title,
  subheading,
  maxWidth,
  pageTitle,
  isLoading = false,
  requiresAdministrativeRole = false,
  children,
  disableContainerGutters,
  action = undefined,
  sticky = false,
  breadcrumbs,
  sx,
  slots,
  ...boxRest
}) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const { isLargeDevice, isMobileDevice, isNarrowDevice } =
    useCommonBreakpoints();

  const { ref } = useInView({
    threshold: 0.1,
    delay: DOHERTY_THRESHOLD,
    onChange: (inView) => {
      setShowBackToTop(!inView);
    }
  });

  const drawerOffsetWidth = isLargeDevice ? DRAWER_WIDTH_EXPANDED : 0;
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        // provides a small amount of spacing for scrolling and being able to see the bottom of the page
        marginBottom: 1,
        minHeight: 'calc(100vh - 100px)',
        width: (theme) => ({
          xs: `calc(${theme.breakpoints.values.xs}px - (${drawerOffsetWidth}px + 4.5rem))`,
          sm: `calc(${theme.breakpoints.values.sm}px - (${drawerOffsetWidth}px + 4.5rem))`,
          md: `calc(${theme.breakpoints.values.md}px - (${drawerOffsetWidth}px + 4.5rem))`,
          lg: `calc(${theme.breakpoints.values.lg}px - (${drawerOffsetWidth}px + 4.5rem))`,
          xl: `calc(${theme.breakpoints.values.xl}px - (${drawerOffsetWidth}px + 4.5rem))`
        }),
        ...(sx || {})
      }}
      {...boxRest}>
      <Head>
        <title>Moderne - {title}</title>
      </Head>

      {/* Required to provide spacing for top-nav app-bar */}
      {isNarrowDevice && <Toolbar variant="dense" />}
      {slots?.banner}

      <Container ref={ref} maxWidth={false}>
        <BreadcrumbsComponent links={breadcrumbs || [{ label: title }]} />
      </Container>

      {/* Page title in its own container to remove vertical lines */}
      {pageTitle && (
        <PageTitle
          action={action}
          sticky={sticky}
          maxWidth={maxWidth}
          subheading={subheading}>
          {pageTitle}
        </PageTitle>
      )}
      <Container
        disableGutters={disableContainerGutters ?? isMobileDevice}
        maxWidth={maxWidth || false}>
        {/* Check for administrative restrictions */}
        {requiresAdministrativeRole ? (
          <WithAdministrative>
            <LoadingAndThenDisplay loading={isLoading}>
              {children}
            </LoadingAndThenDisplay>
          </WithAdministrative>
        ) : (
          <LoadingAndThenDisplay loading={isLoading}>
            {children}
          </LoadingAndThenDisplay>
        )}
      </Container>
      <Fade in={showBackToTop}>
        <Box
          sx={{
            // css to persistently in the bottom right corner of the screen even when scrolling
            position: 'fixed',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2)
          }}>
          <Fab
            sx={{
              backgroundColor: (theme) => `${theme.palette.blue.main}20`,
              color: (theme) => theme.palette.blue.main
            }}
            title="Back to top"
            size="small"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>
            <UpArrowIcon />
          </Fab>
        </Box>
      </Fade>
    </Box>
  );
};

const PageTitle: FunctionComponentWithChildren<
  Pick<LayoutProps, 'action' | 'sticky' | 'maxWidth' | 'subheading'>
> = ({ children, action, sticky, maxWidth, subheading }) => {
  const { isLargeDevice } = useCommonBreakpoints();
  const pageTitleBox = (
    <CenteredOpposingBoxes
      sx={{
        marginTop: sticky ? 1 : 0.5,
        marginBottom: sticky ? 1 : 1.5,
        alignItems: 'center',
        flexWrap: 'wrap'
      }}>
      <Box>
        <Typography variant="h4" component="h1">
          {children}
        </Typography>
        {subheading && <Typography variant="caption">{subheading}</Typography>}
      </Box>
      {action}
    </CenteredOpposingBoxes>
  );

  return sticky ? (
    <StickyBox
      bgcolor="inherit"
      id="sticky-header"
      top={isLargeDevice ? 0 : TOP_BANNER_HEIGHT}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        paddingTop: 0.5,
        paddingBottom: 0.25
      }}>
      <Container maxWidth={maxWidth || false}>{pageTitleBox}</Container>
    </StickyBox>
  ) : (
    <Container maxWidth={maxWidth || false}>{pageTitleBox}</Container>
  );
};

const LoadingAndThenDisplay: FunctionComponentWithChildren<{
  loading: boolean;
}> = ({ loading, children }) => {
  return loading ? (
    <CenteredBox
      sx={{
        minHeight: '50vh'
      }}>
      <Loading caption="Loading" wait={DOHERTY_THRESHOLD} />
    </CenteredBox>
  ) : (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        maxWidth: '100%'
      }}>
      {children}
    </Box>
  );
};
