import { usePopupState } from 'material-ui-popup-state/hooks';
import { FunctionComponent } from 'react';

import { SvgIcon } from '@mui/material';

import { useRouter } from 'next/router';
import { determineHref } from '../../../helpers/link.helper';
import { useAuthorization } from '../../../hooks/use-authorization.hooks';
import { useCommandPalette } from '../../../hooks/use-command-palette.hooks';
import { AdminIcon } from '../../../icons/icons';
import { LeftNavigationMenuItem } from './left-nav-menu-item.component';
import { MenuItemWithSubMenu } from './menu-with-sub-menu.component';
import { getLeftNavAdminMenuItems } from './navigation-items';

export const AdminMenu: FunctionComponent = () => {
  const router = useRouter();
  const popoverMenu = usePopupState({
    variant: 'popover',
    popupId: 'admin-menu'
  });
  const { pathname } = useRouter();

  const { isAdministrator = false } = useAuthorization();

  useCommandPalette(
    isAdministrator
      ? [
          ...getLeftNavAdminMenuItems().map((i) => ({
            label: `Go to ${i.label}`,
            description: i.description,
            action: () => router.push(determineHref(i.href)),
            keyboardShortcuts: i.shortcut
              ? {
                  key: i.shortcut
                }
              : undefined
          }))
        ]
      : []
  );

  if (!isAdministrator) {
    return null;
  }

  const menuItem = {
    icon: <SvgIcon component={AdminIcon} inheritViewBox />,
    label: 'Admin',
    description: 'Administrative tasks and settings',
    href: '#'
  };
  const selectedMenuItemRegex = new RegExp(`/admin`, 'i');
  return (
    <MenuItemWithSubMenu
      menuItem={menuItem}
      selected={selectedMenuItemRegex.test(pathname)}
      popoverState={popoverMenu}
      slotProps={{
        menuProps: {
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          sx: {
            left: '0.5rem'
          }
        }
      }}>
      {getLeftNavAdminMenuItems().map((menuItem, idx) => (
        <LeftNavigationMenuItem
          key={`admin-menu-${idx}`}
          menuItem={menuItem}
          onClick={popoverMenu.close}
        />
      ))}
    </MenuItemWithSubMenu>
  );
};
