import { Box, styled } from '@mui/material';

export const GraphiQLContainer = styled(Box)(() => ({
  minHeight: '80vh',
  height: '80vh',
  maxWidth: '90vw',
  ['& .graphiql-container *']: {
    boxSizing: 'unset !important'
  }
}));
