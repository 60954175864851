import { FunctionComponent } from 'react';

import { Box, Divider, List, ListItemButton } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import { focusGlobalSearchInput } from '../../helpers/focus.helper';
import {
  handleDownArrow,
  handleUpArrow
} from '../../helpers/keyboard-event.helper';
import { useUserPreferenceStore } from '../../stores/user-preference.store';
import { SectionHeading } from './global-search.styled';

export const PreviousSearchQueries: FunctionComponent<{
  previousQueryCount?: number;
}> = ({ previousQueryCount = 5 }) => {
  const { setValue } = useFormContext();
  const { previousSearchQueries } = useUserPreferenceStore();

  const handleRecipeNavigation = (query) => (e) => {
    if (e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) {
      setValue('query', query);
      focusGlobalSearchInput(e);
    }
    handleDownArrow(e, e.target.parentElement);
    handleUpArrow(e);
  };

  return previousSearchQueries.length > 0 ? (
    <Box
      sx={{
        width: 'auto'
      }}>
      <SectionHeading>Recent searches:</SectionHeading>
      <Divider />
      <List>
        {previousSearchQueries
          .slice(0, previousQueryCount)
          .map((query, index) => (
            <ListItemButton
              key={index}
              onClick={handleRecipeNavigation(query)}
              onKeyDown={handleRecipeNavigation(query)}>
              {query}
            </ListItemButton>
          ))}
      </List>
    </Box>
  ) : null;
};
