import {
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  listItemButtonClasses,
  listItemTextClasses,
  styled
} from '@mui/material';
import { textColor } from '../../../themes/moderne-theme';

export const LeftNavigationMenuItemText = styled(ListItemText)(({ theme }) => ({
  fontWeight: 400,
  letterSpacing: 0.2,
  fontSize: '.9rem',
  color: textColor('medium')(theme),
  [`& .${listItemTextClasses.primary}`]: {
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.5rem'
  }
}));

export const LeftNavigationListItemButton = styled(
  (props: ListItemButtonProps & { href?: string }) => (
    <ListItemButton {...props} />
  )
)(({ theme }) => ({
  [`&.${listItemButtonClasses.selected}, &.${listItemButtonClasses.root}:hover`]:
    {
      background: `linear-gradient(45deg, ${theme.palette.indigo[50]}, ${theme.palette.blue[150]})`
    }
}));

export const LeftNavigationListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.common.black,
  minWidth: '2.25rem'
}));
