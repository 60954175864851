import { Box, Radio, Typography } from '@mui/material';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react';

import { PureFunction } from '@bonsai-components/utility-types';
import {
  DataGridProProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridGroupNode,
  GridRowId,
  GridRowSelectionModel,
  gridClasses,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import {
  Organization,
  OrganizationInput
} from '../../@types/repository-groups';
import { GraphQLOperations } from '../../__generated__/apollo-helpers';
import { useDeleteUserOrganizationMutation } from '../../__generated__/apollo-hooks';
import { downloadStringAsFile } from '../../helpers/download.helper';
import { minimalRepositoryReducer } from '../../helpers/repository.helpers';

import { useConfirm } from 'material-ui-confirm';
import {
  useGetAllOrganizationRepositories,
  useOrganizations
} from '../../hooks/use-organizations.hooks';
import { OrganizationIcon } from '../../icons/icons';
import { ModerneDataGrid } from '../styled-components/data-grids/data-grids.styled';
import { getOrganizationColumns } from './organizations.columns';
import {
  MY_ORGANIZATIONS_GROUPING,
  OrganizationRowData,
  getOrganizationRows
} from './organizations.rows';
import { MyOrganizationsToolbar } from './organizations.toolbar.component';
import { UserOrganizationDialog } from './user-organization-editor/user-organization-dialog.component';

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.path;

const groupingColDef: DataGridProProps['groupingColDef'] = {
  headerName: 'Organization',
  minWidth: 200,
  flex: 1,
  disableColumnMenu: false,
  hideDescendantCount: true,
  filterable: true,
  sortable: true,
  sortingOrder: ['asc', 'desc', null]
};

export const OrganizationsTable: FunctionComponent<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const {
    organizations,
    selectedOrganization,
    getOrganizationById,
    setSelectedOrganization
  } = useOrganizations();

  const [deleteOrganization] = useDeleteUserOrganizationMutation();
  const rows = getOrganizationRows(organizations ?? []);
  const [selectedOrganizationRow, setSelectedOrganizationRow] =
    useState<OrganizationRowData>(null);
  const [isCopy, setIsCopy] = useState<boolean>(false);

  const confirm = useConfirm();

  const { getOrganizationRepositories } = useGetAllOrganizationRepositories();

  const handleConfirmRemoveOrganization = (orgRowData) => () =>
    confirm({
      title: 'Delete organization',
      description: (
        <React.Fragment>
          {' '}
          <Typography
            sx={{
              marginBottom: 2
            }}>
            Are you sure you want to delete this organization?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start'
            }}>
            <OrganizationIcon />
            <Box>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 2,
                  marginBottom: 1,
                  fontWeight: 700
                }}>
                {orgRowData?.name}
              </Typography>
            </Box>
          </Box>
        </React.Fragment>
      )
    }).then(() => removeOrganization(orgRowData.id));

  const copyOrganization = (orgRowData: OrganizationRowData) => {
    setIsCopy(true);
    setSelectedOrganizationRow(orgRowData);
  };

  const editOrganization = (orgRowData: OrganizationRowData) => {
    setIsCopy(false);
    setSelectedOrganizationRow(orgRowData);
  };

  const removeOrganization = async (id: string) => {
    await deleteOrganization({
      variables: { id },
      refetchQueries: [GraphQLOperations.Query.organizations]
    });
    setSelectedOrganization(null);
  };

  const exportOrganization = async (id: string) => {
    const organization = { ...(getOrganizationById(id) as OrganizationInput) };

    if (organization?.isUserOrganization) {
      organization.repositories = await getOrganizationRepositories({
        id
      });
    }

    const group = minimalRepositoryReducer<Organization>(organization);

    downloadStringAsFile(
      JSON.stringify(group, null, 2),
      `${organization.name}.json`
    );
  };

  const columns = getOrganizationColumns({
    ActionColumnProps: {
      copyOrganization,
      editOrganization,
      removeOrganizationModal: handleConfirmRemoveOrganization,
      exportOrganization
    }
  });

  const handleRowSelectionModelChange = (newModel) => {
    if (newModel.length === 0) {
      onClose && onClose();
      return;
    }
    const newSelection = newModel
      ?.filter((id) => id !== selectedOrganization?.id)
      .at(0);
    setSelectedOrganization(String(newSelection));
    onClose && onClose();
  };

  const selectionModel: GridRowSelectionModel = selectedOrganization?.id
    ? [selectedOrganization?.id]
    : [];

  const getExpansionStatus: PureFunction<GridGroupNode, boolean> = (node) => {
    const selectedRow = rows.find((row) => row.id === selectionModel.at(0));

    // rules:
    // 1. always expand my organizations by default
    // 2. if the current selection is a root node, do not expand
    // 3. if the current selection is a child node, expand all the parent nodes
    if (node.groupingKey === MY_ORGANIZATIONS_GROUPING) {
      return true;
    } else if (node.depth === 0 && selectedRow?.id === node.id) {
      return false;
    } else {
      return selectedRow?.path.some((p) => p === String(node.groupingKey));
    }
  };

  const apiRef = useGridApiRef();

  const recursivelyCollapseChildren = useCallback(
    (row: GridRowId) => {
      const node = apiRef.current.getRowNode(row);
      if (node.type === 'group' && node.childrenExpanded) {
        // collapse the current row
        apiRef.current.setRowChildrenExpansion(row, false);
        // recursively collapse the children
        node.children.forEach(recursivelyCollapseChildren);
      }
    },
    [apiRef]
  );

  useEffect(() => {
    return apiRef?.current?.subscribeEvent('rowExpansionChange', (params) => {
      if (!params.childrenExpanded) {
        params.children.forEach(recursivelyCollapseChildren);
      }
    });
  }, [apiRef, recursivelyCollapseChildren]);

  useEffect(() => {
    return apiRef?.current?.subscribeEvent('filterModelChange', (params) => {
      apiRef.current.getAllRowIds().forEach((row) => {
        const lookup = apiRef.current.state.filter.filteredRowsLookup;
        const node = apiRef.current.getRowNode(row);
        if (node.type === 'group' && lookup[row]) {
          const selectedRow = rows.find(
            (row) => row.id === selectedOrganization?.id
          );
          apiRef.current.setRowChildrenExpansion(
            row,
            params.quickFilterValues.length > 0 ||
              selectedRow?.path.some((p) => p === String(node.groupingKey))
          );
        }
      });
    });
  }, [apiRef, rows, selectedOrganization?.id]);
  return (
    <>
      <ModerneDataGrid
        apiRef={apiRef}
        autoPageSize={false}
        rows={rows}
        columns={columns}
        // tree
        treeData
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        defaultGroupingExpansionDepth={1}
        isGroupExpandedByDefault={getExpansionStatus}
        // selection
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        isRowSelectable={(params) =>
          params.row.id !== MY_ORGANIZATIONS_GROUPING
        }
        initialState={{
          pinnedColumns: {
            left: [
              GRID_ACTIONS_COLUMN_TYPE,
              GRID_CHECKBOX_SELECTION_COL_DEF.field
            ]
          },
          sorting: {
            sortModel: [{ field: 'isOrganization', sort: 'desc' }]
          },
          columns: {
            columnVisibilityModel: {
              isOrganization: false,
              name: false
            }
          }
        }}
        slots={{
          toolbar: MyOrganizationsToolbar,
          baseCheckbox: Radio
        }}
        slotProps={{
          baseCheckbox: {
            size: 'small'
          }
        }}
        sx={{
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none'
            }
          }
        }}
      />

      {selectedOrganizationRow && (
        <UserOrganizationDialog
          orgRowData={selectedOrganizationRow}
          open={Boolean(removeOrganization)}
          onClose={() => setSelectedOrganizationRow(null)}
          copy={isCopy}
        />
      )}
    </>
  );
};
