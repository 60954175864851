/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component, ReactNode } from 'react';

import {
  commandMenuIndexForShortcutSettings,
  searchIndexForShortcutSettings
} from '../components/settings/manage-accessibility/keyboard-shortcuts-settings';
import { LOCAL_STORAGE_USER_PREFERENCES } from '../constants/general';
import { getObjectFromLocalStorage } from '../helpers/local-storage.helper';

type UserContextProps = {
  children: ReactNode[] | ReactNode;
};

type KeyboardShortcutsSettings = 'commandMenuShortcut' | 'searchMenuShortcut';

export type UserPreferences = {
  [key in KeyboardShortcutsSettings]?: number;
} & { minimizeDownloadManager?: boolean };
export type UserContextState = {
  preferences: UserPreferences;
  setPreferences: (preference: UserPreferences, reload?: boolean) => void;
  getDefaultsFromLocalStorage: () => UserPreferences;
};

const initialState: UserContextState = {
  preferences: {
    commandMenuShortcut: commandMenuIndexForShortcutSettings,
    searchMenuShortcut: searchIndexForShortcutSettings
  },
  setPreferences: () => false,
  getDefaultsFromLocalStorage: () => undefined
};

export const UserContext = React.createContext(initialState);

export class UserProvider extends Component<
  UserContextProps,
  UserContextState
> {
  readonly state: UserContextState = {
    ...initialState,

    setPreferences: (preference, refresh = false) => {
      const updatedPreferences = { ...this.state.preferences, ...preference };
      this.updateLocalStorage(updatedPreferences);

      this.setState(() => ({
        preferences: updatedPreferences
      }));

      if (refresh) {
        window.location.reload();
      }
    },
    getDefaultsFromLocalStorage: () => {
      return getObjectFromLocalStorage<UserPreferences>(
        LOCAL_STORAGE_USER_PREFERENCES,
        {}
      );
    }
  };

  componentDidMount(): void {
    const userPreferences = this.state.getDefaultsFromLocalStorage();

    this.setState(() => ({
      preferences: {
        commandMenuShortcut: initialState.preferences.commandMenuShortcut,
        searchMenuShortcut: initialState.preferences.searchMenuShortcut,

        ...userPreferences
      }
    }));
  }

  private updateLocalStorage(newState: UserContextState['preferences']): void {
    localStorage.setItem(
      LOCAL_STORAGE_USER_PREFERENCES,
      JSON.stringify(newState)
    );
  }

  render(): JSX.Element {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
