import {
  Dialog,
  dialogClasses,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent, useContext } from 'react';

import {
  CommandPaletteContext,
  CommandPaletteItem
} from '../../contexts/command-palette.context';
import { UserContext } from '../../contexts/user.context';
import { KeyboardShortcuts } from '../keyboard-shortcuts/keyboard-shortcuts.component';
import { searchCommandShortcutSettings } from '../settings/manage-accessibility/keyboard-shortcuts-settings';

type CommandPaletteItemMeta = Omit<CommandPaletteItem, 'action'>;

export const CommandCheatSheet: FunctionComponent = () => {
  const { commands, showCheatSheet, toggleCheatSheet } = useContext(
    CommandPaletteContext
  );

  const {
    preferences: { commandMenuShortcut, searchMenuShortcut }
  } = useContext(UserContext);

  const navigationCommands: CommandPaletteItemMeta[] = [];
  const actionCommands: CommandPaletteItemMeta[] = [
    {
      label: 'Open command palette',
      keyboardShortcuts:
        searchCommandShortcutSettings[commandMenuShortcut].bindings
    },
    {
      label: 'Focus search',
      keyboardShortcuts:
        searchCommandShortcutSettings[searchMenuShortcut].bindings
    }
  ];

  commands.forEach((command) => {
    if (command.keyboardShortcuts) {
      if (command.label.startsWith('Go to')) {
        navigationCommands.push(command);
      } else {
        actionCommands.push(command);
      }
    }
  });

  return (
    <Dialog
      open={showCheatSheet}
      onClose={toggleCheatSheet}
      fullWidth
      sx={{
        [`& .${dialogClasses.paper}`]: {
          position: 'absolute',
          top: 50
        }
      }}>
      <Box
        sx={{
          padding: 2
        }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'center',
            marginBottom: 1
          }}>
          Keyboard shortcuts
        </Typography>
        <Box></Box>
        <KeyboardShortcutGrouping title="Actions" commands={actionCommands} />
        <KeyboardShortcutGrouping
          title="Navigation"
          commands={navigationCommands}
        />
      </Box>
    </Dialog>
  );
};

const sortByLabel = (a: CommandPaletteItemMeta, b: CommandPaletteItemMeta) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const KeyboardShortcutGrouping: FunctionComponent<{
  title: string;
  commands: CommandPaletteItemMeta[];
}> = ({ title, commands }) => (
  <Table
    sx={{
      borderColor: (theme) => theme.palette.blue.main,
      borderStyle: 'solid',
      borderWidth: 2,
      borderRadius: 2,
      borderCollapse: 'separate',
      marginBottom: 2
    }}>
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={2}
          sx={{
            textAlign: 'center',
            backgroundColor: (theme) => theme.palette.blue.main
          }}>
          <Typography
            variant="h6"
            sx={{
              color: 'white'
            }}>
            {title}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {commands.sort(sortByLabel).map((command, index) => (
        <TableRow key={index}>
          <TableCell
            sx={{
              paddingX: 2,
              paddingY: 0.25,
              borderBottom: index === commands.length - 1 ? 'none' : undefined
            }}>
            <Typography variant="inherit">{command.label}</Typography>
          </TableCell>
          <TableCell
            sx={{
              paddingX: 2,
              paddingY: 0.25,
              borderBottom: index === commands.length - 1 ? 'none' : undefined
            }}>
            <KeyboardShortcuts shortcuts={command.keyboardShortcuts} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);
