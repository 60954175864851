import kebabcase from 'lodash.kebabcase';

import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { Menu, MenuProps, Tooltip } from '@mui/material';
import {
  PopupState,
  bindPopover,
  bindTrigger
} from 'material-ui-popup-state/hooks';
import React from 'react';
import {
  LeftNavigationListItemButton,
  LeftNavigationListItemIcon,
  LeftNavigationMenuItemText
} from './left-navigation.styled';
import { LeftNavMenuProps } from './navigation-items';

type MenuItemWithSubMenuProps = {
  selected?: boolean;
  popoverState: PopupState;
  menuItem: LeftNavMenuProps;
  slotProps?: {
    menuProps: Partial<MenuProps>;
  };
};

export const MenuItemWithSubMenu: FunctionComponentWithChildren<
  MenuItemWithSubMenuProps
> = ({ menuItem, popoverState, slotProps, children, selected }) => {
  return (
    <React.Fragment>
      <LeftNavigationListItemButton
        sx={{
          paddingLeft: 1.25,
          paddingRight: 2.5
        }}
        selected={selected}
        data-testid={`global-nav-${kebabcase(menuItem.label)}-button`}
        {...bindTrigger(popoverState)}>
        <LeftNavigationListItemIcon>{menuItem.icon}</LeftNavigationListItemIcon>
        <Tooltip title={menuItem.description} placement="right" arrow>
          <LeftNavigationMenuItemText
            primaryTypographyProps={{
              variant: 'body2'
            }}>
            {menuItem.label}
          </LeftNavigationMenuItemText>
        </Tooltip>
      </LeftNavigationListItemButton>

      <Menu
        {...bindPopover(popoverState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        {...slotProps?.menuProps}>
        {children}
      </Menu>
    </React.Fragment>
  );
};
