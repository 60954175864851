import { SEARCH_INPUT_ID } from '../constants/general';

const FOCUSABLE_SELECTOR =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled):not([tabindex="-1"]),*[tabindex]:not([tabindex="-1"])';

/**
 * Determines either next or previous focusable element
 */
function findAdjacentFocusableNode(
  node: HTMLElement | EventTarget,
  direction: 'next' | 'previous',
  container?: HTMLElement
): HTMLElement | Element | null {
  const allFocusableElements = Array.from(
    (container || document).querySelectorAll(FOCUSABLE_SELECTOR)
  );
  const targetIndex =
    allFocusableElements.indexOf(node as HTMLElement) +
    (direction === 'next' ? 1 : -1);

  return allFocusableElements[targetIndex] || null;
}

/**
 * Focuses the next focusable element
 */
export function focusNextFocusableNode(
  node: HTMLElement | EventTarget,
  container?: HTMLElement
): void {
  const nextFocusable = findAdjacentFocusableNode(node, 'next', container);

  if (nextFocusable && nextFocusable instanceof HTMLElement) {
    nextFocusable.focus();
  }
}

/**
 * Focuses the previous focusable element
 */
export function focusPreviousFocusableNode(
  node: HTMLElement | EventTarget,
  container?: HTMLElement
): void {
  const nextFocusable = findAdjacentFocusableNode(node, 'previous', container);
  if (nextFocusable && nextFocusable instanceof HTMLElement) {
    nextFocusable.focus();
  }
}

/**
 * Looks for a focusable within Element you provide and focuses on the
 * first focusable found.
 */
export const focusFirstFocusable = (element: Element): void => {
  const firstFocusable = element?.querySelector(FOCUSABLE_SELECTOR);
  (firstFocusable as HTMLElement)?.focus();
};

/**
 * Focuses the global search input
 */
export const focusGlobalSearchInput = (e: React.KeyboardEvent<Element>) => {
  e.preventDefault();
  (document.activeElement as HTMLElement)?.blur();
  document.getElementById(SEARCH_INPUT_ID).focus();
};
