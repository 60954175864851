import React, { FunctionComponent } from 'react';

import { Button, ButtonProps, IconButton, SxProps, Theme } from '@mui/material';

import { CollapseIcon, ExpandIcon } from '../../../icons/icons';

type ExpandCollapseProps = {
  variant?: 'icon' | 'iconWithText';
  expandText?: string;
  collapseText?: string;
  collapseIcon?: React.ReactNode;
  expandIcon?: React.ReactNode;
  isExpanded: boolean;
  color?: ButtonProps['color'];
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onTouchStart?: (event: React.TouchEvent<Element>) => void;
  'aria-controls'?: string;
  'aria-describedby'?: string;
  'aria-haspopup'?: boolean;
  sx?: SxProps<Theme>;
};

export const ExpandCollapse: FunctionComponent<ExpandCollapseProps> = ({
  variant = 'icon',
  expandText = 'Show',
  collapseText = 'Hide',
  collapseIcon = <CollapseIcon />,
  expandIcon = <ExpandIcon />,
  isExpanded,
  color = 'secondary',
  onClick,
  ...rest
}) => {
  const testId = `${isExpanded ? 'collapse' : 'expand'}-button`;
  if (variant === 'iconWithText') {
    return (
      <Button
        data-testid={testId}
        color={color}
        startIcon={isExpanded ? collapseIcon : expandIcon}
        onClick={onClick}
        size="small"
        {...rest}>
        {isExpanded ? collapseText : expandText}
      </Button>
    );
  } else {
    return (
      <IconButton
        data-testid={testId}
        color={color}
        onClick={onClick}
        size="small"
        {...rest}>
        {isExpanded ? collapseIcon : expandIcon}
      </IconButton>
    );
  }
};
