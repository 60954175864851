import { getSession, signOut } from 'next-auth/react';

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  fromPromise
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';

import { PureFunction } from '@bonsai-components/utility-types';
import { TypedTypePolicies } from '../__generated__/apollo-helpers';
import { TypedTypePolicies as WorkerTypedTypePolicies } from '../__generated__/apollo-helpers-worker';
import generatedIntrospection from '../__generated__/fragment-matcher.json';
import { isAServerError } from '../components/utilities/graphql-error/graphql-error.component';
import {
  DGS_ERROR_PREAMBLE_REGEX,
  GRAPHQL_INTERNAL_ERROR
} from '../constants/general';
import { KNOWN_SIGN_IN_ERRORS } from '../constants/messages';
import { NotificationContextState } from '../contexts/notification.context';
import { logError } from './logger.helper';
import { signInWithError } from './next.helpers';
import {
  getOrganizationAndNameFromPath,
  mapRepositoryFragmentToId
} from './repository.helpers';
import { parseJwt } from './string.helper';

const workerTypePolicies: WorkerTypedTypePolicies = {
  Query: {
    fields: {
      recipeRunResultsByRepository: relayStylePagination([
        'id',
        'repositoryInput',
        'filter'
      ])
    }
  }
};

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      allRecipeRuns: relayStylePagination(['sortOrder', 'filterBy']),
      previousRecipeRuns: relayStylePagination(['sortOrder', 'filterBy']),
      events: relayStylePagination(['filters']),
      searchRecipes: relayStylePagination(['query', 'featureAi']),
      agents: relayStylePagination(),
      commitJobs: relayStylePagination(),
      repositories: relayStylePagination(['filter']),
      ...workerTypePolicies.Query.fields,
      visualizationRunHistory: relayStylePagination([
        'visualizationId',
        'organizationId',
        'recipeRunId'
      ]),
      users: relayStylePagination(['filter'])
    }
  },
  Commit: {
    keyFields: ['repository']
  },
  CommitJob: {
    fields: {
      commits: relayStylePagination(['filterBy', 'orderBy']),
      pullRequestActionJobs: relayStylePagination(['type'])
    }
  },
  PullRequestStatistics: {
    keyFields: ['id', 'type']
  },
  Recipe: {
    keyFields: false
  },
  RecipeRun: {
    fields: {
      recipe: {
        merge: true
      },
      totals: {
        merge: true
      },
      summaryResultsPages: relayStylePagination(['orderBy', 'filterBy'])
    }
  },
  RecipeArtifact: {
    keyFields: ['groupId', 'artifactId']
  },
  Repository: {
    keyFields: ['id'],
    fields: {
      id: {
        read(_, { readField }) {
          return mapRepositoryFragmentToId({
            __typename: readField('__typename'),
            origin: readField('origin'),
            path: readField('path'),
            branch: readField('branch') || 'no-branch'
          });
        }
      }
    }
  },
  OrphanedRepository: {
    fields: {
      organization: (_, { readField }) =>
        getOrganizationAndNameFromPath(readField('path') as string)
          .organization ?? 'unknown',
      name: (_, { readField }) =>
        getOrganizationAndNameFromPath(readField('path') as string).name ??
        'unknown'
    }
  },
  Organization: {
    fields: {
      repositoriesPages: relayStylePagination(['filter'])
    }
  },
  VisualizationRun: {
    fields: {
      repositories: relayStylePagination()
    }
  }
};

const errorLink = (showError: NotificationContextState['renderNotification']) =>
  onError(({ response, graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors && response) {
      response.errors = graphQLErrors
        /**
         * Removes internal error message from error message
         * Since `graphQLErrors` is a readonly array, we need to replace the message with a space because nothing would trigger a different error
         */
        .filter(({ message }) => !GRAPHQL_INTERNAL_ERROR.test(message))
        .map((error) => {
          /**
           * Removes DGS exception preamble from error message
           * example:
           * com.netflix.graphql.dgs.exceptions.DgsEntityNotFoundException: Branch not found.
           *  -> Branch not found.
           */
          const modifiedError = {
            ...error,
            message: error.message.replace(DGS_ERROR_PREAMBLE_REGEX, '')
          };
          logError({
            name: String(modifiedError.extensions?.code),
            message: `[${operation.operationName}]: ${modifiedError.message}`
          });
          return modifiedError;
        });
    }
    if (networkError) {
      logError(networkError);
      if (isAServerError(networkError) && networkError.statusCode === 401) {
        // check expiration time of accessToken from `authorizationHeader`
        // if the expiration time has not lapsed unexpired, token was revoked by some means so redirect to sign-in page
        // otherwise request a new session and retry the operation
        const token = operation
          .getContext()
          .headers['Authorization'].substring(7);
        const { exp } = parseJwt(token as string);
        const accessTokenExpiration = new Date(exp * 1000).getTime();
        const currentTime = new Date().getTime();

        if (currentTime < accessTokenExpiration) {
          // eslint-disable-next-line no-console
          console.warn('Token revoked. Redirecting to sign in page');
          return signInWithError(
            KNOWN_SIGN_IN_ERRORS.invalidToken,
            window.location.pathname
          );
        }

        return fromPromise(getSession())
          .filter(Boolean)
          .flatMap((data) => {
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${data.accessToken}`
              }
            });
            return forward(operation);
          });
      } else if (
        isAServerError(networkError) &&
        networkError.statusCode === 429
      ) {
        showError(
          'error',
          'Rate limit exceeded. Please try again in a few minutes.',
          {
            placement: {
              horizontal: 'center',
              vertical: 'top'
            }
          }
        );
      }
    }
  });

const appendOperation = (uri, options) => {
  try {
    const { operationName } = JSON.parse(options.body);
    const fetchUri = new URL(uri);
    if (operationName) {
      fetchUri.searchParams.append('op', operationName);
    }
    return fetch(fetchUri.toString(), options);
  } catch (error) {
    return fetch(uri, options);
  }
};

const httpLink = (apiGatewayUrl: string) =>
  new HttpLink({
    uri: apiGatewayUrl,
    fetch: appendOperation
  });

const getModerneApiAuthLink = (accessToken: string): ApolloLink =>
  setContext(async (_, { headers, ...context }) => {
    const customHeaders = {};
    let token;

    try {
      const { exp } = parseJwt(accessToken as string);
      token = accessToken;
      const accessTokenExpiration = new Date(exp * 1000).getTime();
      const currentTime = new Date().getTime();

      if (currentTime > accessTokenExpiration) {
        try {
          const newToken = await getSession();
          token = newToken['accessToken'];
        } catch (e) {
          logError(e);
          return signOut();
        }
      }

      if (token) {
        customHeaders['Authorization'] = `Bearer ${token}`;
      }
    } catch (e) {
      logError(e);
    }

    return {
      headers: {
        ...headers,
        ...customHeaders
      },
      ...context
    };
  });

type ApolloClientCreator = {
  accessToken: string;
  apiGatewayUrl: string;
  showError: NotificationContextState['renderNotification'];
};

export const createApolloClient: PureFunction<
  ApolloClientCreator,
  ApolloClient<NormalizedCacheObject>
> = ({ accessToken, apiGatewayUrl, showError }) => {
  const apolloInMemoryCache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies
  });

  return new ApolloClient({
    uri: apiGatewayUrl,
    link: ApolloLink.from([
      errorLink(showError),
      getModerneApiAuthLink(accessToken),
      httpLink(apiGatewayUrl)
    ]),
    cache: apolloInMemoryCache,
    connectToDevTools: true,
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      }
    }
  });
};
