import {
  Box,
  CircularProgress,
  IconButton,
  SvgIcon,
  Typography
} from '@mui/material';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  AuthenticatedDownloadManifest,
  AuthorizedDownloadManifest,
  DownloadManifestIndexDb,
  DownloadState,
  DownloadsContext,
  REMOVE_DOWNLOAD_DELAY
} from '../../contexts/downloads.context';
import { showNotification } from '../../helpers/notification.helper';
import { useAuthDownload } from '../../hooks/use-auth-download.hooks';
import { ErrorIcon } from '../../icons/icons';
import {
  ModCancelIcon,
  ModCheckedOutlineIcon
} from '../../icons/moderne-icons';
import { FlexBox } from '../styled-components/layouts/layouts.styled';
import { DownloadTimer } from './download-timer.component';
import { DownloadableRow } from './downloadable-row.styled';

const isAuthzDownloadManifest = (
  manifest: AuthenticatedDownloadManifest | AuthorizedDownloadManifest
): manifest is AuthorizedDownloadManifest => {
  return (manifest as AuthorizedDownloadManifest).repository !== undefined;
};

export const Downloadable: FunctionComponent<{
  manifest: (AuthenticatedDownloadManifest | AuthorizedDownloadManifest) &
    DownloadManifestIndexDb;
}> = ({ manifest }) => {
  const [error, setError] = useState<Error | undefined>(undefined);

  const authDownload = useAuthDownload();
  const { removeDownload, updateDownloadState } = useContext(DownloadsContext);

  const dismissDownload = () => removeDownload(manifest.fileName);

  useEffect(() => {
    const handleDownload = async () => {
      // eslint-disable-next-line no-console
      console.info(manifest.fileName, manifest.state);
      if (manifest.state === 'PENDING') {
        try {
          await updateDownloadState(manifest.indexDbId, 'DOWNLOADING');

          const resolvedSourcePath = manifest.onDownloadStart
            ? await manifest.onDownloadStart()
            : manifest.sourcePath;

          await authDownload({
            path: resolvedSourcePath,
            fileName: manifest.fileName,
            Repository: isAuthzDownloadManifest(manifest)
              ? manifest.repository
              : undefined,
            errorMessage: 'Error downloading file'
          });

          await updateDownloadState(manifest.indexDbId, 'SUCCESS');

          await showNotification(
            'Download complete',
            `File: ${manifest.fileName}`
          );

          manifest.onDownloadEnd && manifest.onDownloadEnd();

          setTimeout(
            () => removeDownload(manifest.fileName),
            REMOVE_DOWNLOAD_DELAY
          );
        } catch (e) {
          showNotification('Error with download', `File: ${manifest.fileName}`);
          await updateDownloadState(manifest.indexDbId, 'FAILURE');
          setError(e);
        }
      }
    };

    handleDownload();
    // only run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DownloadableItem
      filename={manifest.fileName}
      state={manifest.state}
      error={error}
      startTimestamp={manifest.startTimestamp || 0}
      clearDownload={dismissDownload}
    />
  );
};

export const DownloadStateIcon: FunctionComponent<{
  state: DownloadState;
}> = ({ state }) => {
  switch (state) {
    case 'SUCCESS':
      return (
        <SvgIcon
          component={ModCheckedOutlineIcon}
          inheritViewBox
          color="success"
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(32.5)
          }}
        />
      );
    case 'FAILURE':
      return (
        <SvgIcon
          component={ErrorIcon}
          inheritViewBox
          color="error"
          sx={{
            fontSize: (theme) => theme.typography.pxToRem(32.5)
          }}
        />
      );
    case 'PENDING':
    default:
      return (
        <CircularProgress
          size={25}
          sx={{ margin: (theme) => theme.typography.pxToRem(4) }}
        />
      );
  }
};

const DownloadableItem: FunctionComponent<{
  filename: string;
  state: DownloadState;
  startTimestamp: number;
  clearDownload?: () => void;
  error?: Error;
}> = ({ filename, state, clearDownload, error, startTimestamp }) => {
  return (
    <DownloadableRow>
      <Box>
        <Typography
          variant="body2"
          sx={{
            textOverflow: 'ellipsis'
          }}>
          {filename}
        </Typography>
        {state === 'PENDING' && (
          <DownloadTimer startTimestamp={startTimestamp} />
        )}
        {state === 'SUCCESS' && (
          <Typography variant="caption">Completed</Typography>
        )}
        {error && (
          <Typography
            variant="subtitle2"
            sx={{
              color: 'error'
            }}>
            {error.message}
          </Typography>
        )}
      </Box>
      <FlexBox>
        <IconButton
          onClick={clearDownload}
          size="small"
          disabled={state === 'SUCCESS'}>
          <SvgIcon
            component={ModCancelIcon}
            inheritViewBox
            color={state === 'SUCCESS' ? 'inherit' : 'secondary'}
            sx={{ fontSize: (theme) => theme.typography.pxToRem(32.5) }}
          />
        </IconButton>
        <IconButton size="small" sx={{ padding: 0 }}>
          <DownloadStateIcon state={state} />
        </IconButton>
      </FlexBox>
    </DownloadableRow>
  );
};
