import {
  focusNextFocusableNode,
  focusPreviousFocusableNode
} from './focus.helper';

export const handleDownArrow = (
  e: React.KeyboardEvent,
  container?: HTMLElement
) => {
  if (e.key === 'ArrowDown') {
    e.preventDefault();
    focusNextFocusableNode(e.target, container);
  }
};

export const handleUpArrow = (
  e: React.KeyboardEvent,
  container?: HTMLElement
) => {
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    focusPreviousFocusableNode(e.target, container);
  }
};
