/* eslint-disable no-console */
import Dexie, { Table } from 'dexie';
import {
  Job,
  OrganizationLegacy,
  StoredDownloadJob
} from '../@types/repository-groups';
import { GraphLink, GraphNodeData } from '../stores/recipe-topology.store';
import {
  getSelectedOrganizationIdFromBrowserStorage,
  setSelectedOrganizationIdInBrowserStorage
} from './repository.helpers';

export type BuilderRecipe = {
  id: string;
  name: string;
  description?: string;
  nodes: GraphNodeData[];
  links: GraphLink[];
};

export type RecentRecipeDBEntry = {
  id: string;
  name: string;
  description: string;
  createdAt: number;
};

class ModerneIndexedDb extends Dexie {
  private repositoryGroups: Table<OrganizationLegacy>;
  organizations: Table<OrganizationLegacy>;
  jobs: Table<Job>;
  downloadJobs: Table<StoredDownloadJob>;
  builderRecipes: Table<BuilderRecipe>;
  recentRecipes: Table<RecentRecipeDBEntry>;

  constructor() {
    super('moderne');
    this.version(1).stores({
      repositoryGroups: 'name'
    });
    this.version(2)
      .stores({
        repositoryGroups: 'name,selected,organization,priority'
      })
      .upgrade((tx) => {
        return tx
          .table('repositoryGroups')
          .toCollection()
          .modify((repo) => {
            const currentSelection =
              getSelectedOrganizationIdFromBrowserStorage();
            if (!repo.organization) {
              repo.organization = 0;
              repo.priority = 0;
            }
            repo.selected = repo.name === currentSelection ? 1 : 0;
          });
      });
    this.version(3).stores({
      jobs: 'id,message,terminalFunctionKey,polledQueryParameters'
    });

    this.version(4)
      .stores({
        organizations: 'id,name,selected,organization,priority'
      })
      .upgrade(() => {
        return db.repositoryGroups.toArray().then((objs) => {
          objs.forEach((obj) => {
            const id = obj.id || obj.name;
            return db.organizations.add({ ...obj, id });
          });
        });
      });

    this.version(5).stores({
      downloadJobs: 'id,manifest'
    });
    // Migrate previous selected stored in indexedDB to local/session storage
    this.version(6)
      .stores({
        organizations: 'id,name,selected,organization,priority'
      })
      .upgrade(() => {
        return db.organizations
          .toCollection()
          .toArray()
          .then((objs) => {
            objs.forEach((obj) => {
              if (obj.selected) {
                setSelectedOrganizationIdInBrowserStorage(obj.id);
              }
            });
          });
      });
    this.version(7).stores({
      /**
       * id: unique identifier
       * name: name of the customRecipe
       * nodes: list of recipe nodes
       * links: list of recipe links
       */
      builderRecipes: 'id,name,nodes,links'
    });
    this.version(8).stores({
      recentRecipes: 'id,name,description,createdAt'
    });
  }
}

export const db = new ModerneIndexedDb();
