import { StateCreator, create } from 'zustand';
import { PersistOptions, persist } from 'zustand/middleware';

type PersistedStore<T> = [['zustand/persist', T]];

type StoreUpdater<T> = {
  update: (update: Partial<T>) => void;
};

export type Store<T> = T & StoreUpdater<T>;

export function createPersistedStore<T>(
  obj: StateCreator<T>,
  options: PersistOptions<Store<T>>,
  initialization?: (o: T) => T
) {
  return create<Store<T>, PersistedStore<Store<T>>>(
    persist(
      (set, get, state) => {
        const migratedState = initialization?.(obj(set, get, state));

        return {
          ...(migratedState || obj(set, get, state)),
          update: (update) => set(update as Store<T>)
        };
      },
      {
        ...options,
        name: `moderne:${options.name}`
      }
    )
  );
}

export function createStore<T>(obj: StateCreator<T>) {
  return create<Store<T>>((set, get, store) => {
    return {
      ...obj(set, get, store),
      update: (update) => set(update as Store<T>)
    };
  });
}
