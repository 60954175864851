import {
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  cardHeaderClasses
} from '@mui/material';
import {
  PopupState,
  bindDialog,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React, { FunctionComponent } from 'react';
import { useOrganizations } from '../../../hooks/use-organizations.hooks';
import { ChevronRightIcon, OrganizationIcon } from '../../../icons/icons';
import { ClosableDialogTitle } from '../../utilities/dialog/closable-dialog-title.component';
import { OrganizationsTable } from '../organizations.table.component';

export const CurrentOrganizationCard: FunctionComponent<{
  popupState: PopupState;
}> = () => {
  const { selectedOrganization } = useOrganizations();
  const popoverState = usePopupState({
    variant: 'dialog',
    popupId: 'organization-selector'
  });

  return (
    <React.Fragment>
      <Card
        variant="outlined"
        sx={{
          margin: 0,
          padding: 0,
          borderColor: 'blue.300',
          borderRadius: 1,
          backdropFilter: 'blur(10px)',
          backgroundColor: `#ffffff90`,
          width: '100%'
        }}>
        <CardHeader
          onClick={popoverState.open}
          sx={{
            paddingX: 2,
            paddingY: 0.75,
            alignItems: 'flex-start',
            [`& .${cardHeaderClasses.content}`]: {
              alignSelf: 'center'
            }
          }}
          title={selectedOrganization?.name}
          titleTypographyProps={{
            sx: {
              fontSize: '1rem',
              wordBreak: 'break-word'
            }
          }}
          avatar={<OrganizationIcon />}
          action={
            <IconButton
              size="small"
              onClick={popoverState.open}
              color="primary">
              <ChevronRightIcon color="primary" />
            </IconButton>
          }
        />
      </Card>
      <Dialog {...bindDialog(popoverState)} fullWidth fullScreen maxWidth="lg">
        <ClosableDialogTitle
          title="Organizations"
          onClose={popoverState.close}></ClosableDialogTitle>
        <DialogContent>
          <OrganizationsTable onClose={popoverState.close} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
